import { RequestService } from './../../../_servicios/request.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Producto } from '../../../_modelos/producto.model';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import * as fromProducto from './../producto.actions';
import { Categoria } from 'src/app/_modelos/categoria.model';

declare var $: any;

@Component({
  selector: 'app-mant-producto-actualizar',
  templateUrl: './mant-producto-actualizar.component.html',
  styleUrls: ['./mant-producto-actualizar.component.css']
})
export class MantProductoActualizarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  @Input() idProducto: Observable<any>;
  productos: Producto[] = [];
  operando: boolean;
  categorias: Categoria[];
  public producto: Producto = null;

  formularioActualizar: FormGroup;

  constructor(
    private store: Store<AppState>,
    private request: RequestService,
    private formBuilder: FormBuilder
  ) { }

  seleccionar(idProducto: number) {
    this.producto = JSON.parse(JSON.stringify(this.productos)).find(i => i.idProducto === idProducto);
    console.log(this.producto);
    if (this.producto) {
      this.setFormulario(this.producto);
    }
  }

  ngOnInit() {
    this.operando = false;
    this.buildForm();

    this.request.getListarCategorias().subscribe(
      res => {
        this.categorias = res;
        console.log(this.categorias);
      }
    );

    this.store.subscribe(
      state => {
        this.productos = state.productos;
        console.log(this.productos);
      }
    );
  }

  buildForm() {
    this.formularioActualizar = this.formBuilder.group({
      categoria: [1],
      nombre: [null, [Validators.required]],
      descripcion: [null],
      stock: [null],
      precio: [null],
      imagen: [null]
    });
  }

  private setFormulario(producto: Producto) {
    this.formularioActualizar.controls.categoria.setValue(producto.idCategoria);
    this.formularioActualizar.controls.nombre.setValue(producto.nombre);
    this.formularioActualizar.controls.descripcion.setValue(producto.descripcion);
    this.formularioActualizar.controls.stock.setValue(producto.stockFisico);
    this.formularioActualizar.controls.precio.setValue(producto.precioVenta);
    this.formularioActualizar.controls.imagen.setValue(producto.imagen);
  }

  public actualizarProducto() {

    if (this.formularioActualizar.invalid) {
      alert('Formulario con errores');
      return;
    }

    this.operando = true;

    const producto = new Producto();
    producto.idProducto = this.producto.idProducto;
    producto.codigo = '100';
    producto.descripcion = this.formularioActualizar.controls.descripcion.value;
    producto.idCategoria = this.formularioActualizar.controls.categoria.value;
    producto.marca = 'marca';
    producto.nombre = this.formularioActualizar.controls.nombre.value;
    producto.stockFisico = this.formularioActualizar.controls.stock.value;
    producto.stockLogico = this.formularioActualizar.controls.stock.value;
    producto.precioVenta = this.formularioActualizar.controls.precio.value;
    producto.imagen = '';

    console.log(producto);

    this.request.putActualizarProducto(producto).subscribe(
      data => {
        console.log(data);
        if (data.status) {
          this.evento.next('rerender');
          $('#modalMantProductoActualizar').modal('hide');
          const action = new fromProducto.EditarProductoAction(
            producto.idProducto,
            producto.idCategoria,
            producto.codigo,
            producto.codigo,
            producto.nombre,
            producto.marca,
            producto.descripcion,
            producto.stockFisico,
            producto.stockLogico,
            producto.precioVenta,
            producto.imagen,
            1
          );
          console.log(action);
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
