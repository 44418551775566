export class Asignacion {
  idAsignacion: number;
  idVendedor: number;
  idJalador: number;
  idLocal: number;
  habilitado: number;

  constructor( idAsignacion?: number, idVendedor?: number, idJalador?: number, habilitado?: number, idLocal?: number ) {
    this.idAsignacion = idAsignacion;
    this.idVendedor = idVendedor;
    this.idJalador = idJalador;
    this.idLocal = idLocal;
    this.habilitado = habilitado;
  }

}
