import { Action } from '@ngrx/store';
import { Categoria } from '../../_modelos/categoria.model';

export const INICIALIZAR_TODO_CATEGORIA = '[CATEGORIA] Inicializar Todo Categoria';
export const AGREGAR_CATEGORIA = '[CATEGORIA] Agregar Categoria';
export const EDITAR_CATEGORIA = '[CATEGORIA] Editar Categoria';
export const BORRAR_CATEGORIA = '[CATEGORIA] Borrar Categoria';
export const BORRAR_TODO_CATEGORIA = '[CATEGORIA] Borrar Todo Categoria';

export class InicializarCategoriaAction implements Action {
  readonly type = INICIALIZAR_TODO_CATEGORIA;

  constructor(
    public categorias: Categoria[]
  ) {}
}

export class AgregarCategoriaAction implements Action {
  readonly type = AGREGAR_CATEGORIA;

  constructor(
    public idCategoria: number,
    public nombre: string,
    public descripcion: string,
    public habilitado: number
  ) { }
}

export class EditarCategoriaAction implements Action {
  readonly type = EDITAR_CATEGORIA;

  constructor(
    public idCategoria: number,
    public nombre: string,
    public descripcion: string
  ) { }
}

export class BorrarCategoriaAction implements Action {
  readonly type = BORRAR_CATEGORIA;

  constructor(
    public idCategoria: number
  ) {}
}

export type Acciones =  AgregarCategoriaAction |
                        EditarCategoriaAction  |
                        BorrarCategoriaAction  |
                        InicializarCategoriaAction;
