import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import { Empleado } from '../../../_modelos/empleado.model';
import { Observable } from 'rxjs';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { RequestService } from '../../../_servicios/request.service';
import * as fromEmpleado from './../empleado.actions';

declare var $: any;

@Component({
  selector: 'app-mant-empleado-actualizar',
  templateUrl: './mant-empleado-actualizar.component.html',
  styleUrls: ['./mant-empleado-actualizar.component.css']
})
export class MantEmpleadoActualizarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  @Input() idEmpleado: Observable<any>;
  empleados: Empleado[] = [];
  operando: boolean;
  public empleado: Empleado = null;

  formularioActualizar: FormGroup;

  constructor(
    private store: Store<AppState>,
    private request: RequestService,
    private formBuilder: FormBuilder
  ) { }

  seleccionar(idEmpleado: number) {
    this.empleado = JSON.parse(JSON.stringify(this.empleados)).find(i => i.idEmpleado === idEmpleado);
    console.log(this.empleado);
    if (this.empleado) {
      this.setFormulario(this.empleado);
    }
  }

  ngOnInit() {
    this.operando = false;
    this.buildForm();

    this.store.subscribe(
      state => {
        this.empleados = state.empleados;
        console.log(this.empleados);
      }
    );
  }

  buildForm() {
    this.formularioActualizar = this.formBuilder.group({
      nombres: [null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      apellidoPaterno: [null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      apellidoMaterno: [null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      fechaNacimiento: [null],
      tipoDocumento: [null, Validators.required],
      numeroDocumento: [null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(13), Validators.minLength(8)]],
      direccion: [null],
      telefono: [null, [Validators.pattern('^[0-9]*$'), Validators.maxLength(7), Validators.minLength(7)]],
      celular: [null, [Validators.pattern('^[0-9]*$'), Validators.maxLength(9), Validators.minLength(9)]],
      cargo: [null, Validators.required]
    });
  }

  private setFormulario(empleado: Empleado) {
    this.formularioActualizar.controls.nombres.setValue(empleado.nombres);
    this.formularioActualizar.controls.apellidoPaterno.setValue(empleado.apellidoPaterno);
    this.formularioActualizar.controls.apellidoMaterno.setValue(empleado.apellidoMaterno);
    this.formularioActualizar.controls.fechaNacimiento.setValue(empleado.fechaNacimiento);
    this.formularioActualizar.controls.tipoDocumento.setValue(empleado.tipoDocumento);
    this.formularioActualizar.controls.numeroDocumento.setValue(empleado.numeroDocumento);
    this.formularioActualizar.controls.direccion.setValue((empleado.direccion) ? empleado.direccion : '');
    this.formularioActualizar.controls.telefono.setValue(empleado.telefono);
    this.formularioActualizar.controls.celular.setValue(empleado.celular);
    this.formularioActualizar.controls.cargo.setValue(empleado.idCargo);
  }

  validarFormulario() {
    if (this.formularioActualizar.controls.nombres.invalid) { this.formularioActualizar.controls.nombres.markAsDirty(); }
    if (this.formularioActualizar.controls.apellidoPaterno.invalid) { this.formularioActualizar.controls.apellidoPaterno.markAsDirty(); }
    if (this.formularioActualizar.controls.apellidoMaterno.invalid) { this.formularioActualizar.controls.apellidoMaterno.markAsDirty(); }
    if (this.formularioActualizar.controls.fechaNacimiento.invalid) { this.formularioActualizar.controls.fechaNacimiento.markAsDirty(); }
    if (this.formularioActualizar.controls.tipoDocumento.invalid) { this.formularioActualizar.controls.tipoDocumento.markAsDirty(); }
    if (this.formularioActualizar.controls.numeroDocumento.invalid) { this.formularioActualizar.controls.numeroDocumento.markAsDirty(); }
    if (this.formularioActualizar.controls.direccion.invalid) { this.formularioActualizar.controls.direccion.markAsDirty(); }
    if (this.formularioActualizar.controls.telefono.invalid) { this.formularioActualizar.controls.telefono.markAsDirty(); }
    if (this.formularioActualizar.controls.celular.invalid) { this.formularioActualizar.controls.celular.markAsDirty(); }
    if (this.formularioActualizar.controls.cargo.invalid) { this.formularioActualizar.controls.cargo.markAsDirty(); }
  }

  public actualizarEmpleado() {

    if (this.formularioActualizar.invalid) {
      alert('Formulario con errores');
      this.validarFormulario();
      return;
    }

    this.operando = true;

    const empleado = new Empleado();
    empleado.idEmpleado = this.empleado.idEmpleado;
    empleado.nombres = this.formularioActualizar.controls.nombres.value;
    empleado.apellidoPaterno = this.formularioActualizar.controls.apellidoPaterno.value;
    empleado.apellidoMaterno = this.formularioActualizar.controls.apellidoMaterno.value;
    empleado.fechaNacimiento = this.formularioActualizar.controls.fechaNacimiento.value;
    empleado.tipoDocumento = this.formularioActualizar.controls.tipoDocumento.value;
    empleado.numeroDocumento = this.formularioActualizar.controls.numeroDocumento.value;
    empleado.direccion = this.formularioActualizar.controls.direccion.value;
    empleado.telefono = this.formularioActualizar.controls.telefono.value;
    empleado.celular = this.formularioActualizar.controls.celular.value;
    empleado.idCargo = this.formularioActualizar.controls.cargo.value;

    this.request.putActualizarEmpleado(empleado).subscribe(

      data => {
        console.log(data);
        if (data.status) {
          this.evento.next('rerender');
          $('#modalMantEmpleadoActualizar').modal('hide');
          const action = new fromEmpleado.EditarEmpleadoAction(
            empleado.idEmpleado,
            empleado.nombres,
            empleado.apellidoPaterno,
            empleado.apellidoMaterno,
            empleado.fechaNacimiento,
            empleado.foto,
            empleado.direccion,
            empleado.telefono,
            empleado.celular,
            empleado.numeroDocumento,
            empleado.tipoDocumento,
            empleado.idLocalEmpresa,
            1,
            empleado.idCargo,
            ''
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
