import { Cliente } from './cliente.model';
export class VCabecera {
  tipoVenta: string;
  tipoOperacion: number;
  serie: string;
  numero: string;
  requiereOSE: boolean;
  cliente: Cliente;
  fechaInicio: string;
  fechaVencimiento: string;
  tipoMoneda: number;
  igv: number;
  condicionPago: string;
  ordenCompra: string;
  montoGravado: number;
  montoIgv: number;
  montoDescuento: number;
  montoTotal: number;
  estado: string;

  constructor(
    tipoVenta: string,
    tipoOperacion: number,
    serie: string,
    numero: string,
    requiereOSE: boolean,
    cliente: Cliente,
    fechaInicio: string,
    fechaVencimiento: string,
    tipoMoneda: number,
    igv: number,
    condicionPago: string,
    ordenCompra: string,
    montoGravado: number,
    montoIgv: number,
    montoDescuento: number,
    montoTotal: number,
    estado: string
  ){
    this.tipoVenta = tipoVenta;
    this.tipoOperacion = tipoOperacion;
    this.serie = serie;
    this.numero = numero;
    this.requiereOSE = requiereOSE;
    this.cliente = cliente;
    this.fechaInicio = fechaInicio;
    this.fechaVencimiento = fechaVencimiento;
    this.tipoMoneda = tipoMoneda;
    this.igv = igv;
    this.condicionPago = condicionPago;
    this.ordenCompra = ordenCompra;
    this.montoGravado = montoGravado;
    this.montoIgv = montoIgv;
    this.montoDescuento = montoDescuento;
    this.montoTotal = montoTotal;
    this.estado = estado;
  }
}
