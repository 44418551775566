import { RequestService } from './../../../_servicios/request.service';
import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, OnChanges } from '@angular/core';
import { Local } from '../../../_modelos/local.model';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import * as fromLocal from './../local.actions';
import { Observable } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-mant-local-eliminar',
  templateUrl: './mant-local-eliminar.component.html',
  styleUrls: ['./mant-local-eliminar.component.css']
})
export class MantLocalEliminarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  @Input() idLocal: Observable<any>;
  locales: Local[] = [];
  operando: boolean;
  public local: Local = null;

  constructor(
    private store: Store<AppState>,
    private request: RequestService
  ) { }

  seleccionar(idLocal: number) {
    this.local = JSON.parse(JSON.stringify(this.locales)).find(i => i.idLocal === idLocal);
    console.log(this.local);
  }

  ngOnInit() {
    this.operando = false;
    this.store.subscribe(
      state => {
        this.locales = state.locales;
        console.log(this.locales);
      }
    );
  }

  eliminarLocal(idLocal: number) {
    this.operando = true;
    const local = new Local(idLocal);
    this.request.putEliminarLocal(local).subscribe(

      data => {
        console.log(data);
        if (data.status) {
          this.evento.next('rerender');
          $('#modalMantLocalEliminar').modal('hide');
          const action = new fromLocal.BorrarLocalAction(
            local.idLocal
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
