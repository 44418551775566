import { DocumentoVentaDetalle } from './documentoventadetalle.model';
export class DocumentoVenta {
  idUsuario: number;                 // 1
  idDocumentoVenta: number;          // 1
  tipoDocumento: string;             // BOLETA
  serie: string;                     // B001
  numero: number;                    // 1
  idVendedor: number;                // 5
  idJalador: number;                 // 4
  vendedor: string;                  // 72665585
  jalador: string;                   // 71445862
  fecha: string;                     // 10/10/2019
  moneda: string;                    // 1
  igv: number;                       // 18
  tipoRegalo: number;
  cantidadRegalo: number;
  condicionPago: number;             // 1: Contado | 2: Tarjeta
  numeroOperacion: string;           // OP193278
  clienteTipoDocumento: number;      // 1
  clienteNumeroDocumento: string;    // 72663345
  clienteDenominacion: string;       // Erick Cerna Ruiz
  clienteDireccion: string;          // Lima
  clienteEmail: string;              // erick@gmail.com
  clienteCelular: string;            // 988988988
  descuentoGlobal: number;           // 0
  totalDescuento: number;            // 10
  totalActicipo: number;             // 0
  totalGravada: number;              // 100
  totalExonerada: number;            // 0
  totalInafecta: number;             // 0
  totalIGV: number;                  // 18
  totalGratuita: number;             // 0
  totalOtros: number;                // 0
  total: number;                     // 108
  enviarSunat: boolean;              // true
  enviarCliente: boolean;            // false;
  detalle: DocumentoVentaDetalle[];  // Detalle
}
