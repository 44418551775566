import { Component, OnInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { RequestService } from '../../_servicios/request.service';
import { Autenticacion } from '../../_modelos/autenticacion.model';
import { Subscription, Subject } from 'rxjs';
import { DocumentoVenta } from '../../_modelos/documentoventa.model';
import { AuthService } from '../../_servicios/auth.service';
import { Liquidaciondiaria } from '../../_modelos/liquidar.model';
import { formatDate } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'app-liquidar',
  templateUrl: './liquidar.component.html',
  styleUrls: ['./liquidar.component.css']
})
export class LiquidarComponent implements OnInit {

  @ViewChildren('registros') things: QueryList<any>;

  dtOptions: any = {};
  dtTrigger: Subject<null> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  autenticacion: Autenticacion;
  autenticacionSubscription: Subscription;
  liquidacion: Liquidaciondiaria[] = [];

  constructor(
    private request: RequestService,
    private authService: AuthService
  ) {
    this.autenticacionSubscription = this.authService.autenticacion.subscribe(user => {
      this.autenticacion = user;
      console.log(this.autenticacion);
    });
  }


  ngOnInit() {

    this.dtOptions = {
      initComplete: () => {
        $('div.dataTables_filter input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          text: 'Excel',
          title: 'Liquidación de Ventas - ' + formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          footer: true
        },
        {
          extend: 'pdfHtml5',
          text: 'PDF',
          title: 'Liquidación de Ventas - ' + formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          footer: true
        },
        {
          extend: 'print',
          text: 'Imprimir',
          title: 'Liquidación de Ventas - ' + formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          footer: true
        }
      ],
      'footerCallback': function (row, data, start, end, display) {
        var api = this.api(), data;

        // Remove the formatting to get integer data for summation
        const intVal = (i) => {
          return typeof i === 'string' ? Number(i.replace(/[\$,]/g, '')) * 1 : typeof i === 'number' ? i : 0;
        };

        // Total over all pages
        const total = api
          .column(6)
          .data()
          .reduce((a, b) => {
            return intVal(a) + intVal(b);
          }, 0);

        // Total over this page
        const pageTotal = api
          .column(6, { page: 'current' })
          .data()
          .reduce((a, b) => {
            return intVal(a) + intVal(b);
          }, 0);

        // Update footer
        $(api.column(6).footer()).html(
          'S/ ' + ((pageTotal * 100) / 100) + ' ( S/ ' + ((total * 100) / 100) + ' total)'
        );
      },
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_ registros',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: 'Siguiente',
          sPrevious: 'Anterior'
        },
        oAria: {
          sSortAscending: ': Activar para ordenar la columna de manera ascendente',
          sSortDescending: ': Activar para ordenar la columna de manera descendente'
        }
      }
    };

    this.request.getVentasLiquidarUsuario(this.autenticacion.idUsuario).subscribe(
      resp => {
        console.log(resp);
        this.liquidacion = resp;
        this.dtTrigger.next();
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  visualizarVenta(idDocumentoVenta: number) {
    console.log(idDocumentoVenta);
    console.log(this.liquidacion);
    $('#modalVisualizarVenta').modal('show');
  }

  liquidar() {
    if (confirm('¿Está seguro que va a liquidar las ventas mostradas?')) {
      this.request.postVentasLiquidarDiario(this.liquidacion).subscribe(
        data => {
          if (data.status) {
            alert(data.message);
            this.liquidacion = [];
            this.rerender();
          }
        },
        error => {
          alert(error.message);
        }
      );
    } else {

    }
    console.log('Liquidar');
  }

}
