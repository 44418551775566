import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RequestService } from 'src/app/_servicios/request.service';
import { AppState } from 'src/app/app.reducers';
import { Store } from '@ngrx/store';
import { Producto } from 'src/app/_modelos/producto.model';
import * as fromProducto from './../producto.actions';
import { Categoria } from 'src/app/_modelos/categoria.model';

declare var $: any;

@Component({
  selector: 'app-mant-producto-insertar',
  templateUrl: './mant-producto-insertar.component.html',
  styleUrls: ['./mant-producto-insertar.component.css']
})
export class MantProductoInsertarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  formularioInsertar: FormGroup;
  operando: boolean;
  categorias: Categoria[];

  constructor(
    private requestService: RequestService,
    private store: Store<AppState>,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.operando = false;
    this.buildForm();

    this.requestService.getListarCategorias().subscribe(
      res => {
        this.categorias = res;
        console.log(this.categorias);
      }
    );
  }

  buildForm() {
    this.formularioInsertar = this.formBuilder.group({
      categoria: [1],
      nombre: [null, [Validators.required]],
      descripcion: [null],
      stock: [null],
      precio: [null],
      imagen: [null]
    });
  }

  exit() {
    this.formularioInsertar.reset();
    $('#modalMantProductoInsertar').modal('hide');
  }

  agregarProducto() {

    if (this.formularioInsertar.invalid) {
      alert('Formulario con errores');
      return;
    }

    this.operando = true;

    const producto = new Producto();
    producto.codigo = '100';
    producto.descripcion = this.formularioInsertar.controls.descripcion.value;
    producto.idCategoria = this.formularioInsertar.controls.categoria.value;
    producto.marca = 'marca';
    producto.nombre = this.formularioInsertar.controls.nombre.value;
    producto.stockFisico = this.formularioInsertar.controls.stock.value;
    producto.stockLogico = this.formularioInsertar.controls.stock.value;
    producto.precioVenta = this.formularioInsertar.controls.precio.value;
    producto.imagen = '';

    this.requestService.postAgregarProducto(producto).subscribe(
      data => {
        console.log(data);

        if(data.status) {
          this.evento.next('rerender');
          $('#modalMantProductoInsertar').modal('hide');
          const action = new fromProducto.AgregarProductoAction(
            data.data,
            producto.idCategoria,
            producto.codigo,
            producto.codigo,
            producto.nombre,
            producto.marca,
            producto.descripcion,
            producto.stockFisico,
            producto.stockLogico,
            producto.precioVenta,
            producto.imagen,
            1
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
