import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../_servicios/auth.service';
import { Subscription } from 'rxjs';
import { Autenticacion } from '../../_modelos/autenticacion.model';
import { RequestService } from '../../_servicios/request.service';
import { Usuario } from '../../_modelos/usuario.model';

declare var $: any;

@Component({
  selector: 'app-page-modal',
  templateUrl: './page-modal.component.html',
  styleUrls: ['./page-modal.component.css']
})
export class PageModalComponent implements OnInit {

  autenticacion: Autenticacion;
  autenticacionSubscription: Subscription;

  clave: string;

  public claveactual: string;
  public clavenueva: string;
  public clavenuevaconfirmar: string;

  constructor(
    private authService: AuthService,
    private request: RequestService
  ) {
    this.autenticacionSubscription = this.authService.autenticacion.subscribe(user => {
      this.autenticacion = user;
      console.log(this.autenticacion);
    });
  }

  ngOnInit() {
    this.clave = '';
    this.claveactual = '';
    this.clavenueva = '';
    this.clavenuevaconfirmar = '';
  }

  actualizarClave() {

    if (this.claveactual === '' || this.clavenueva === '' || this.clavenuevaconfirmar === '') {
      alert('Los campos son requeridos');
      return;
    }

    if (this.clavenueva !== this.clavenuevaconfirmar) {
      alert('Debe confirmar la clave nueva correctamente');
      return;
    }

    const usuario = new Usuario();
    usuario.idUsuario = this.autenticacion.idUsuario;
    usuario.clave = this.claveactual;
    usuario.claveNueva = this.clavenueva;
    usuario.claveConfirmar = this.clavenuevaconfirmar;
    if (confirm('¿Esta seguro que desea reestablecer su clave?')) {
      $('.gj-cambiar-clave-loading').show();
      $('.gj-cambiar-clave').hide();
      this.request.putActualizarClaveUsuario(usuario).subscribe(
        res => {
          alert('Clave actualizada con éxito');
          $('.gj-cambiar-clave-loading').hide();
          $('.gj-cambiar-clave').show();
        }
      );
    }
  }

  verClave1() {
    if ($('#show_hide_password1 input').attr('type') === 'text') {
      $('#show_hide_password1 input').attr('type', 'password');
      $('#show_hide_password1 button').addClass( 'fa-eye-slash' );
      $('#show_hide_password1 button').removeClass( 'fa-eye' );
    } else {
      if ($('#show_hide_password1 input').attr('type') === 'password') {
        $('#show_hide_password1 input').attr('type', 'text');
        $('#show_hide_password1 button').removeClass( 'fa-eye-slash' );
        $('#show_hide_password1 button').addClass( 'fa-eye' );
      }
    }
  }

  verClave2() {
    if ($('#show_hide_password2 input').attr('type') === 'text') {
      $('#show_hide_password2 input').attr('type', 'password');
      $('#show_hide_password2 button').addClass( 'fa-eye-slash' );
      $('#show_hide_password2 button').removeClass( 'fa-eye' );
    } else {
      if ($('#show_hide_password2 input').attr('type') === 'password') {
        $('#show_hide_password2 input').attr('type', 'text');
        $('#show_hide_password2 button').removeClass( 'fa-eye-slash' );
        $('#show_hide_password2 button').addClass( 'fa-eye' );
      }
    }
  }

  verClave3() {
    if ($('#show_hide_password3 input').attr('type') === 'text') {
      $('#show_hide_password3 input').attr('type', 'password');
      $('#show_hide_password3 button').addClass( 'fa-eye-slash' );
      $('#show_hide_password3 button').removeClass( 'fa-eye' );
    } else {
      if ($('#show_hide_password3 input').attr('type') === 'password') {
        $('#show_hide_password3 input').attr('type', 'text');
        $('#show_hide_password3 button').removeClass( 'fa-eye-slash' );
        $('#show_hide_password3 button').addClass( 'fa-eye' );
      }
    }
  }

}
