import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-venta-registrar',
  templateUrl: './venta-registrar.component.html',
  styleUrls: ['./venta-registrar.component.css']
})
export class VentaRegistrarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
