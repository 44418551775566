import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cliente } from '../../../_modelos/cliente.model';
import { RequestService } from '../../../_servicios/request.service';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import * as fromCliente from './../cliente.actions';

declare var $: any;

@Component({
  selector: 'app-mant-cliente-insertar',
  templateUrl: './mant-cliente-insertar.component.html',
  styleUrls: ['./mant-cliente-insertar.component.css']
})
export class MantClienteInsertarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  formularioInsertar: FormGroup;
  operando: boolean;

  constructor(
    private requestService: RequestService,
    private store: Store<AppState>,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.operando = false;
    this.buildForm();
  }

  buildForm() {
    this.formularioInsertar = this.formBuilder.group({
      tipoDocumento: [1, [Validators.required]],
      numeroDocumento: [null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(13), Validators.minLength(8)]],
      denominacion: [null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      direccion: [null],
      email: [null, Validators.email],
      celular: [null],
      telefono: [null]
    });
  }

  exit() {
    this.formularioInsertar.reset();
    this.formularioInsertar.controls.tipoDocumento.setValue('1');
    $('#modalMantClienteInsertar').modal('hide');
  }

  agregarCliente() {

    if (this.formularioInsertar.invalid) {
      alert('Formulario con errores');
      return;
    }

    this.operando = true;
    const cliente = new Cliente();
    cliente.tipoDocumento = this.formularioInsertar.controls.tipoDocumento.value;
    cliente.numeroDocumento = this.formularioInsertar.controls.numeroDocumento.value;
    cliente.denominacion = this.formularioInsertar.controls.denominacion.value;
    cliente.direccion = this.formularioInsertar.controls.direccion.value;
    cliente.email = this.formularioInsertar.controls.email.value;
    cliente.celular = this.formularioInsertar.controls.celular.value;
    cliente.telefono = this.formularioInsertar.controls.telefono.value;

    this.requestService.postAgregarCliente(cliente).subscribe(

      data => {
        console.log(data);

        if(data.status) {
          this.evento.next('rerender');
          $('#modalMantClienteInsertar').modal('hide');
          const action = new fromCliente.AgregarClienteAction(
            data.data,
            cliente.tipoDocumento,
            cliente.numeroDocumento,
            cliente.denominacion,
            cliente.direccion,
            cliente.email,
            cliente.celular,
            cliente.telefono,
            1
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
