import { AuthService } from './../_servicios/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Alert } from 'selenium-webdriver';

@Component({
  selector: 'app-cambiar-clave',
  templateUrl: './cambiar-clave.component.html',
  styleUrls: ['./cambiar-clave.component.css']
})
export class CambiarClaveComponent implements OnInit {

  formulario: FormGroup;
  submitted = false;
  returnUrl: string;
  token: string;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
    this.route.params.subscribe(res => {
      this.token = res.token;
      this.validarToken();
      console.log('res', res, this.token);
    })
    if (this.authService.autenticacionValue) {
      this.router.navigate(['/home']);
    }
  }
  private validarToken() {
    this.authService.validarToken(this.token).subscribe((response: any) => {
      if (response.status === 0) {
        alert('El link no funciona');
        this.router.navigate(['/']);
      } else if (response.status == 2) {
        alert('El link ya ha sido usado o a caducado');
        this.router.navigate(['/']);
      }
    }, (error: any) => {
      alert('El servicio no esta disponible');
      this.router.navigate(['/']);
    });
  }
  ngOnInit() {
    this.formulario = this.formBuilder.group(
      {
        clave1: ['', Validators.required],
        clave2: ['', Validators.required],
      }
    )

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f() { return this.formulario.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.formulario.invalid) {
      alert('Formulario Inválido');
      return;
    }
    const { clave1, clave2 } = this.formulario.value;
    this.authService.cambiarClaveConToken(clave1, clave2, this.token).subscribe(
      (response: any) => {
        console.log('response', response);
        if (response.status === 1) {
          alert('Felicidades ya cambiaste la clave');
          this.router.navigate(['/']);
        } else if (response.status === 2) {
          alert('El enlace ya ha sido usado');
        } else if (response.status === 3) {
          alert('Las claves no conciden');
        }
      }, (error: any) => {
        alert('El servicio no esta disponible');
      }
    )
    console.log('enviar correo', clave1, clave2, this.token);
  }
}
