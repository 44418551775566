import { VCabecera } from './vcabecera.model';
import { VDetalle } from './vdetalle.model';
export class Venta {
  idVenta: number;
  cabecera: VCabecera;
  detalle: VDetalle[];
  observacion: string;

  constructor(
    idVenta: number,
    cabecera: VCabecera,
    detalle: VDetalle[],
    observacion: string
  ) {
    this.idVenta = idVenta;
    this.cabecera = cabecera;
    this.detalle = detalle;
    this.observacion = observacion;
  }
}
