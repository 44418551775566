import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RequestService } from '../_servicios/request.service';
import { Empleado } from '../_modelos/empleado.model';
import { ReporteVentas } from '../_modelos/reporteventas.model';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-auditoria',
  templateUrl: './auditoria.component.html',
  styleUrls: ['./auditoria.component.css']
})
export class AuditoriaComponent implements OnInit, OnDestroy {

  dtOptions: any = {};
  dtTrigger: Subject<null> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  primeraCarga: boolean;

  empleados: Empleado[];
  reporteVentas: ReporteVentas[];

  selectedAll: boolean;

  public empleadoSelected: number;

  public fecha: string = formatDate(new Date(), 'yyyy-MM-dd', 'en');

  constructor(
    private request: RequestService
  ) {
  }

  ngOnInit() {

    $('.gj-overlay-loading').hide();

    this.primeraCarga = true;
    this.selectedAll = false;

    this.request.getListarEmpleadosAuditoria().subscribe(
      res => {
        this.empleados = res;
      },
      err => {
        console.log(err);
      }
    );

    // const key = 'dataTable';

    // $.fn[key].ext.search.push((settings: any, data: any, dataIndex: any) => {
    //   const fecha = data[13];

    //   const d1 = this.fecha.split('-');
    //   const d2 = this.fecha.split('-');
    //   const c = fecha.split('-');

    //   // console.log(d1, d2, c);

    //   const from = new Date(+d1[0], +d1[1] - 1, +d1[2]);
    //   const to = new Date(+d2[0], +d2[1] - 1, +d2[2]);
    //   const check = new Date(+c[0], +c[1] - 1, +c[2]);

    //   if (check >= from && check <= to) {
    //     return true;
    //   }
    //   return false;
    // });

    this.dtOptions = {
      initComplete: () => {
        $('div.dataTables_filter input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        {
          text: 'Seleccionar todo', action: (e, dt, node, config) => {
            $('.gj-overlay-loading').show();

            if (this.selectedAll) {
              dt.rows().every(function (rowIdx, tableLoop, rowLoop) {
                dt.row(this).deselect();
              });
              this.selectedAll = false;
            } else {
              dt.rows().every(function (rowIdx, tableLoop, rowLoop) {
                dt.row(this).select();
              });
              this.selectedAll = true;
            }

            $('.gj-overlay-loading').hide();
          }
        },
        { extend: 'copy', text: 'Copiar', title: 'Reporte de Auditoría de Ventas - ' + formatDate(new Date(), 'yyyyMMdd', 'en') },
        {
          extend: 'print',
          text: 'Imprimir',
          title: 'Reporte de Auditoría de Ventas - ' + formatDate(new Date(), 'yyyyMMdd', 'en'),
          customize: (win) => {
            $(win.document.body)
              .css('font-size', '10pt');
            $(win.document.body).find('table')
              .addClass('compact')
              .css({ 'font-weight': 'bold', 'color': '#000' });
          }
        },
        { extend: 'excel', text: 'Excel', title: 'Reporte de Auditoría de Ventas - ' + formatDate(new Date(), 'yyyyMMdd', 'en') }
      ],
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_ registros',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: 'Siguiente',
          sPrevious: 'Anterior'
        },
        oAria: {
          sSortAscending: ': Activar para ordenar la columna de manera ascendente',
          sSortDescending: ': Activar para ordenar la columna de manera descendente'
        }
      },
      responsive: true,
      columnDefs: [
        // {
        //   orderable: false,
        //   className: 'select-checkbox',
        //   targets: 0
        // },
        {
          targets: [8, 1],
          visible: false,
          searchable: false
        },
        {
          targets: [4],
          width: '2%'
        }
      ],
      // select: {
      //   style: 'multi'
      // },
      rowGroup: {
        dataSrc: 1,
        // startRender(rows, group) {
        //     return $('<tr/>').
        //     append(
        //       '<td colspan="11">' +
        //       '<div style="margin-bottom: 0px !important;" class="no-mb form-group form-check">' +
        //       '<input type="checkbox" class="form-check-input">' +
        //       '<label class="form-check-label">' + group + '</label>&nbsp;&nbsp;&nbsp;' +
        //       '</div>' +
        //       '</td>'
        //     ).attr('data-name', group);
        // }
      }
    };
  }

  onChange(idEmpleado) {
    $('.gj-overlay-loading').show();
    console.log(idEmpleado);
    this.empleadoSelected = idEmpleado;
    this.request.getListarVentasAuditoria(idEmpleado, this.fecha).subscribe(
      res => {
        this.reporteVentas = res;
        console.log(this.reporteVentas);

        if (this.primeraCarga) {
          this.dtTrigger.next();
          this.primeraCarga = false;
        } else {
          this.reload();
        }

        $('.gj-overlay-loading').hide();

        setTimeout(() => {
          $('.btn-monto').click((event) => {
              console.log('Hola mundo');
              console.log(event.target.id);
              $('#documento').text(event.target.id);
            }
          );
        }, 1000);

      }, err => {
        console.log(err);

        $('.gj-overlay-loading').hide();

      }
    );
  }

  filterById(): void {
    console.log('Entro');
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.draw();
    // });
    $('.gj-overlay-loading').show();
    this.request.getListarVentasAuditoria(this.empleadoSelected, this.fecha).subscribe(
      res => {
        this.reporteVentas = res;
        console.log(this.reporteVentas);

        if (this.primeraCarga) {
          this.dtTrigger.next();
          this.primeraCarga = false;
        } else {
          this.reload();
        }

        setTimeout(() => {
          $('.btn-monto').click((event) => {
              console.log('Hola mundo');
              console.log(event.target.id);
              $('#documento').text(event.target.id);
            }
          );
        }, 1000);

        $('.gj-overlay-loading').hide();

      }, err => {
        console.log(err);

        $('.gj-overlay-loading').hide();

      }
    );
  }

  reload(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  agregarMonto(): any {
    const documento = $('#documento').text();
    const monto = $('#monto').val();
  }

}
