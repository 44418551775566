import { Local } from './../../_modelos/local.model';
import { Action } from '@ngrx/store';

export const INICIALIZAR_TODO_LOCAL = '[LOCAL] Inicializar Todo Local';
export const AGREGAR_LOCAL = '[LOCAL] Agregar Local';
export const EDITAR_LOCAL = '[LOCAL] Editar Local';
export const BORRAR_LOCAL = '[LOCAL] Borrar Local';
export const BORRAR_TODO_LOCAL = '[LOCAL] Borrar Todo Local';

export class InicializarLocalAction implements Action {
  readonly type = INICIALIZAR_TODO_LOCAL;

  constructor(
    public locales: Local[]
  ) {}
}

export class AgregarLocalAction implements Action {
  readonly type = AGREGAR_LOCAL;

  constructor(
    public idLocal: number,
    public nombre: string,
    public descripcion: string,
    public direccion: string,
    public distrito: string,
    public provincia: string,
    public departamento: string,
    public aforo: number,
    public habilitado: number
  ) {}
}

export class EditarLocalAction implements Action {
  readonly type = EDITAR_LOCAL;

  constructor(
    public idLocal: number,
    public nombre: string,
    public descripcion: string,
    public direccion: string,
    public distrito: string,
    public provincia: string,
    public departamento: string,
    public aforo: number
  ) { }
}

export class BorrarLocalAction implements Action {
  readonly type = BORRAR_LOCAL;

  constructor(
    public idLocal: number
  ) {}
}

export type Acciones = AgregarLocalAction |
                        EditarLocalAction |
                        BorrarLocalAction |
                        InicializarLocalAction;
