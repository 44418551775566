import { Component, OnInit, QueryList, ViewChildren, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { RequestService } from '../../../_servicios/request.service';
import { Producto } from '../../../_modelos/producto.model';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import * as fromProducto from './../producto.actions';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { MantProductoActualizarComponent } from '../mant-producto-actualizar/mant-producto-actualizar.component';
import { MantProductoEliminarComponent } from '../mant-producto-eliminar/mant-producto-eliminar.component';

declare var $: any;

@Component({
  selector: 'app-mant-producto-home',
  templateUrl: './mant-producto-home.component.html',
  styleUrls: ['./mant-producto-home.component.css']
})
export class MantProductoHomeComponent implements OnInit, OnDestroy {

  @ViewChild(MantProductoActualizarComponent, {static: false}) actualizarModal: MantProductoActualizarComponent ;
  @ViewChild(MantProductoEliminarComponent, {static: false}) eliminarModal: MantProductoEliminarComponent ;

  productos: Producto[] = [];
  productoSelected: any;

  dtOptions: any = {};
  dtTrigger: Subject<null> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  constructor(
    private requestService: RequestService,
    private store: Store<AppState>
  ) {
    this.requestService.getListarProductos().subscribe(
      productos => {
        const action = new fromProducto.InicializarProductoAction(productos);
        this.dtTrigger.next();
        $('.gj-overlay-loading').hide();
        this.store.dispatch(action);
      }
    );
  }

  ngOnInit() {
    this.dtOptions = {
      initComplete: () => {
        $('div.dataTables_filter input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'copy', text: 'Copiar', title: 'Grupo Jaramillo - Mantenimiento - Productos' },
        { extend: 'print', text: 'Imprimir', title: 'Grupo Jaramillo - Mantenimiento - Productos' },
        { extend: 'excel', text: 'Excel', title: 'Grupo Jaramillo - Mantenimiento - Productos' }
      ],
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_ registros',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: 'Siguiente',
          sPrevious: 'Anterior'
        },
        oAria: {
          sSortAscending: ': Activar para ordenar la columna de manera ascendente',
          sSortDescending: ': Activar para ordenar la columna de manera descendente'
        }
      }
    };

    this.productoSelected = 0;
    this.store.subscribe(
      state => {
        this.productos = state.productos;
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public insertarProducto() {
    $('#modalMantProductoInsertar').modal('show');
  }

  public editarProducto(idProducto: number) {
    this.actualizarModal.seleccionar(idProducto);
    $('#modalMantProductoActualizar').modal('show');
  }

  public eliminarProducto(idProducto: number) {
    this.eliminarModal.seleccionar(idProducto);
    $('#modalMantProductoEliminar').modal('show');
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

}
