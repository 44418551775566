import * as fromVenta from './venta.actions';
import { Venta } from '../../../_modelos/venta.model';
import { VCabecera } from '../../../_modelos/vcabecera.model';
import { Cliente } from '../../../_modelos/cliente.model';
import { VDetalle } from '../../../_modelos/vdetalle.model';

const estadoInicialCliente: Cliente = null;
const estadoInicialVCab: VCabecera = new VCabecera('', 0, '', '', false, estadoInicialCliente, '', '', 0, 0, '', '', 0, 0, 0, 0, '');
const estadoInicialVDet: VDetalle[] = [];
const estadoInicialVenta: Venta = new Venta(0, estadoInicialVCab, estadoInicialVDet, '');

export function ventaReducer(
  state = estadoInicialVenta,
  action: fromVenta.Acciones): Venta {
  switch (action.type) {
    case fromVenta.AGREGAR_VENTA:
      // const venta = new Venta();
      return state;
    default:
      return state;
  }
}
