import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {NgxPrintModule} from 'ngx-print';
import { DataTablesModule } from 'angular-datatables';

import { AppComponent } from './app.component';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


// Componentes Maestros
import { PageSidebarComponent } from './_componentes/page-sidebar/page-sidebar.component';
import { PageNavbarComponent } from './_componentes/page-navbar/page-navbar.component';
import { PageModalComponent } from './_componentes/page-modal/page-modal.component';

/* ---- Modulo Administracion ---- */

// Modulo Administracion - Producto
import { MantProductoHomeComponent } from './administracion/producto/mant-producto-home/mant-producto-home.component';
import { MantProductoInsertarComponent } from './administracion/producto/mant-producto-insertar/mant-producto-insertar.component';
import { MantProductoActualizarComponent } from './administracion/producto/mant-producto-actualizar/mant-producto-actualizar.component';
import { MantProductoEliminarComponent } from './administracion/producto/mant-producto-eliminar/mant-producto-eliminar.component';

// Modulo Administracion - Cliente
import { MantClienteEliminarComponent } from './administracion/cliente/mant-cliente-eliminar/mant-cliente-eliminar.component';
import { MantClienteHomeComponent } from './administracion/cliente/mant-cliente-home/mant-cliente-home.component';
import { MantClienteInsertarComponent } from './administracion/cliente/mant-cliente-insertar/mant-cliente-insertar.component';
import { MantClienteActualizarComponent } from './administracion/cliente/mant-cliente-actualizar/mant-cliente-actualizar.component';

// Modulo Administracion - Categoria
import { MantCategoriaHomeComponent } from './administracion/categoria/mant-categoria-home/mant-categoria-home.component';
import { MantCategoriaInsertarComponent } from './administracion/categoria/mant-categoria-insertar/mant-categoria-insertar.component';
import { MantCategoriaActualizarComponent } from './administracion/categoria/mant-categoria-actualizar/mant-categoria-actualizar.component';
import { MantCategoriaEliminarComponent } from './administracion/categoria/mant-categoria-eliminar/mant-categoria-eliminar.component';

// Modulo Administracion - Empleado
import { MantEmpleadoHomeComponent } from './administracion/empleado/mant-empleado-home/mant-empleado-home.component';
import { MantEmpleadoInsertarComponent } from './administracion/empleado/mant-empleado-insertar/mant-empleado-insertar.component';
import { MantEmpleadoActualizarComponent } from './administracion/empleado/mant-empleado-actualizar/mant-empleado-actualizar.component';
import { MantEmpleadoEliminarComponent } from './administracion/empleado/mant-empleado-eliminar/mant-empleado-eliminar.component';

// Modulo Administracion - Local
import { MantLocalHomeComponent } from './administracion/local/mant-local-home/mant-local-home.component';
import { MantLocalInsertarComponent } from './administracion/local/mant-local-insertar/mant-local-insertar.component';
import { MantLocalActualizarComponent } from './administracion/local/mant-local-actualizar/mant-local-actualizar.component';
import { MantLocalEliminarComponent } from './administracion/local/mant-local-eliminar/mant-local-eliminar.component';


/* ---- Modulo Ventas ---- */

// Modulo Ventas - Transacciones
import { VentaHomeComponent } from './ventas/transacciones/venta/venta-home/venta-home.component';
import { VentaRegistrarComponent } from './ventas/transacciones/venta/venta-registrar/venta-registrar.component';
import { DevolucionHomeComponent } from './ventas/transacciones/devolucion/devolucion-home/devolucion-home.component';
import { DevolucionRegistrarComponent } from './ventas/transacciones/devolucion/devolucion-registrar/devolucion-registrar.component';
import { VentaAnularComponent } from './ventas/transacciones/venta/venta-anular/venta-anular.component';
import { DevolucionAnularComponent } from './ventas/transacciones/devolucion/devolucion-anular/devolucion-anular.component';

// Modulo Ventas - Reportes
import { VentasReporteComponent } from './ventas/reportes/ventas-reporte/ventas-reporte.component';
import { DevolucionesReporteComponent } from './ventas/reportes/devoluciones-reporte/devoluciones-reporte.component';


/* ---- Modulo Usuario ---- */

// Modulo Usuario - Perfil y Clave
import { UsuarioPerfilComponent } from './usuario/usuario-perfil/usuario-perfil.component';
import { UsuarioRecuperarclaveComponent } from './usuario/usuario-recuperarclave/usuario-recuperarclave.component';


/* ---- Modulo Dashboard ---- */
import { DashboardHomeComponent } from './dashboard/dashboard-home/dashboard-home.component';


/* ---- Modulo Reportes ---- */
import { ReportesHomeComponent } from './reportes/reportes-home/reportes-home.component';
import { reducers, metaReducers } from './reducers';
import { MenuUsuarioComponent } from './_componentes/menu-usuario/menu-usuario.component';
import { MenuNotificacionesComponent } from './_componentes/menu-notificaciones/menu-notificaciones.component';
import { MenuAccionesComponent } from './_componentes/menu-acciones/menu-acciones.component';
import { MenuBuscadorComponent } from './_componentes/menu-buscador/menu-buscador.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ConfiguracionHomeComponent } from './configuracion/configuracion-home/configuracion-home.component';
import { ScriptService } from './_servicios/script.service';

import { environment } from '../environments/environment';

/* ---- Reducers ---- */
import { categoriaReducer } from './administracion/categoria/categoria.reducer';
import { empleadoReducer } from './administracion/empleado/empleado.reducer';
import { localReducer } from './administracion/local/local.reducer';
import { clienteReducer } from './administracion/cliente/cliente.reducer';
import { productoReducer } from './administracion/producto/producto.reducer';
import { ventaReducer } from './ventas/transacciones/venta/venta.reducer';
import { ventaDetalleReducer } from './ventas/transacciones/venta/ventadetalle.reducer';
import { VentaFacturaComponent } from './ventas/transacciones/venta/venta-factura/venta-factura.component';
import { VentaBoletaComponent } from './ventas/transacciones/venta/venta-boleta/venta-boleta.component';
import { VentaNotaventaComponent } from './ventas/transacciones/venta/venta-notaventa/venta-notaventa.component';
import { AsignacionComponent } from './administracion/asignacion/asignacion.component';
import { LiquidarComponent } from './ventas/liquidar/liquidar.component';
import { OlvideClaveComponent } from './olvide-clave/olvide-clave.component';
import { CambiarClaveComponent } from './cambiar-clave/cambiar-clave.component';
import { ReporteVentasComponent } from './reportes/reporte-ventas/reporte-ventas.component';
import { ReporteLiquidacionvComponent } from './reportes/reporte-liquidacionv/reporte-liquidacionv.component';
import { AuditoriaComponent } from './auditoria/auditoria.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'olvide-clave', component: OlvideClaveComponent },
  { path: 'cambiar-clave/:token', component: CambiarClaveComponent },
  { path: 'reportes', component: ReportesHomeComponent },
  { path: 'dashboard', component: DashboardHomeComponent },
  { path: 'configuracion', component: ConfiguracionHomeComponent },
  { path: 'mantenimiento/categoria', component: MantCategoriaHomeComponent },
  { path: 'mantenimiento/cliente', component: MantClienteHomeComponent },
  { path: 'mantenimiento/empleado', component: MantEmpleadoHomeComponent },
  { path: 'mantenimiento/local', component: MantLocalHomeComponent },
  { path: 'mantenimiento/producto', component: MantProductoHomeComponent },
  { path: 'mantenimiento/asignacion', component: AsignacionComponent },
  { path: 'ventas/reporte', component: VentasReporteComponent },
  { path: 'ventas/venta', component: VentaHomeComponent },
  { path: 'ventas/devolucion', component: DevolucionHomeComponent },
  { path: 'ventas/liquidar', component: LiquidarComponent},
  { path: 'reportes/ventas', component: ReporteVentasComponent},
  { path: 'reportes/liquidacion-maestro', component: ReporteLiquidacionvComponent},
  { path: 'auditoria', component: AuditoriaComponent},
];


@NgModule({
  declarations: [
    AppComponent,
    PageSidebarComponent,
    PageNavbarComponent,
    PageModalComponent,
    MantProductoHomeComponent,
    MantProductoInsertarComponent,
    MantProductoActualizarComponent,
    MantProductoEliminarComponent,
    MantClienteEliminarComponent,
    MantClienteHomeComponent,
    MantClienteInsertarComponent,
    MantClienteActualizarComponent,
    MantCategoriaHomeComponent,
    MantCategoriaInsertarComponent,
    MantCategoriaActualizarComponent,
    MantCategoriaEliminarComponent,
    MantEmpleadoHomeComponent,
    MantEmpleadoInsertarComponent,
    MantEmpleadoActualizarComponent,
    MantEmpleadoEliminarComponent,
    MantLocalHomeComponent,
    MantLocalInsertarComponent,
    MantLocalActualizarComponent,
    MantLocalEliminarComponent,
    VentaHomeComponent,
    VentaRegistrarComponent,
    DevolucionHomeComponent,
    DevolucionRegistrarComponent,
    VentaAnularComponent,
    DevolucionAnularComponent,
    VentasReporteComponent,
    DevolucionesReporteComponent,
    UsuarioPerfilComponent,
    UsuarioRecuperarclaveComponent,
    DashboardHomeComponent,
    ReportesHomeComponent,
    MenuUsuarioComponent,
    MenuNotificacionesComponent,
    MenuAccionesComponent,
    MenuBuscadorComponent,
    LoginComponent,
    OlvideClaveComponent,
    HomeComponent,
    ConfiguracionHomeComponent,
    VentaFacturaComponent,
    VentaBoletaComponent,
    VentaNotaventaComponent,
    AsignacionComponent,
    LiquidarComponent,
    OlvideClaveComponent,
    CambiarClaveComponent,
    ReporteVentasComponent,
    ReporteLiquidacionvComponent,
    AuditoriaComponent
  ],
  imports: [
    NgxPrintModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    DataTablesModule,
    NgbModule,
    RouterModule.forRoot(routes),
    StoreModule.forRoot({
      categorias: categoriaReducer,
      empleados: empleadoReducer,
      locales: localReducer,
      clientes: clienteReducer,
      productos: productoReducer,
      venta: ventaReducer,
      ventaDetalle: ventaDetalleReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    })
  ],
  providers: [ ScriptService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
