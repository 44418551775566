import { Empleado } from './empleado.model';
import { Cliente } from './cliente.model';
import { Producto } from './producto.model';
export class DatosInicialesVenta {
    serie: string;
    numero: number;
    jaladores: Empleado[];
    clientes: Cliente[];
    productos: Producto[];
}
