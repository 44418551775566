import { PageModalComponent } from './../page-modal/page-modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../_servicios/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Autenticacion } from '../../_modelos/autenticacion.model';

declare var $: any;

@Component({
  selector: 'app-menu-usuario',
  templateUrl: './menu-usuario.component.html',
  styleUrls: ['./menu-usuario.component.css']
})
export class MenuUsuarioComponent implements OnInit {

  autenticacion: Autenticacion;
  autenticacionSubscription: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    this.autenticacionSubscription = this.authService.autenticacion.subscribe(user => {
      this.autenticacion = user;
      console.log(this.autenticacion);
    });
  }

  ngOnInit() {

  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  cambiarClave() {
    $('#claveactual').val('');
    $('#clavenueva').val('');
    $('#clavenuevaconfirmar').val('');
    $('.gj-cambiar-clave').show();
    $('.gj-cambiar-clave-loading').hide();
    $('#modalCambiarClave').modal('show');
  }

}
