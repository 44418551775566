import { Observable } from 'rxjs';
import { RequestService } from 'src/app/_servicios/request.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, SimpleChanges, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Cliente } from '../../../_modelos/cliente.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducers';
import * as fromCliente from './../cliente.actions';

declare var $: any;

@Component({
  selector: 'app-mant-cliente-actualizar',
  templateUrl: './mant-cliente-actualizar.component.html',
  styleUrls: ['./mant-cliente-actualizar.component.css']
})
export class MantClienteActualizarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  @Input() idCliente: Observable<any>;
  clientes: Cliente[] = [];
  operando: boolean;
  public cliente: Cliente = null;

  formularioActualizar: FormGroup;

  constructor(
    private store: Store<AppState>,
    private request: RequestService,
    private formBuilder: FormBuilder
  ) { }

  seleccionar(idCliente: number) {
    this.cliente = JSON.parse(JSON.stringify(this.clientes)).find(i => i.idCliente === idCliente);
    console.log(this.cliente);
    if (this.cliente) {
      this.setFormulario(this.cliente);
    }
  }

  ngOnInit() {
    this.operando = false;
    this.buildForm();

    this.store.subscribe(
      state => {
        this.clientes = state.clientes;
        console.log(this.clientes);
      }
    );
  }

  buildForm() {
    this.formularioActualizar = this.formBuilder.group({
      tipoDocumento: [null, [Validators.required]],
      numeroDocumento: [null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(13), Validators.minLength(8)]],
      denominacion: [null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      direccion: [null],
      email: [null, Validators.email],
      celular: [null],
      telefono: [null]
    });
  }

  private setFormulario(cliente: Cliente) {
    this.formularioActualizar.controls.tipoDocumento.setValue(cliente.tipoDocumento);
    this.formularioActualizar.controls.numeroDocumento.setValue(cliente.numeroDocumento);
    this.formularioActualizar.controls.denominacion.setValue(cliente.denominacion);
    this.formularioActualizar.controls.direccion.setValue(cliente.direccion);
    this.formularioActualizar.controls.email.setValue(cliente.email);
    this.formularioActualizar.controls.celular.setValue(cliente.celular);
    this.formularioActualizar.controls.telefono.setValue(cliente.telefono);
  }

  public actualizarCliente(){

    if (this.formularioActualizar.invalid) {
      alert('Formulario con errores');
      return;
    }

    this.operando = true;

    const cliente = new Cliente();
    cliente.idCliente = this.cliente.idCliente;
    cliente.tipoDocumento = this.formularioActualizar.controls.tipoDocumento.value;
    cliente.numeroDocumento = this.formularioActualizar.controls.numeroDocumento.value;
    cliente.denominacion = this.formularioActualizar.controls.denominacion.value;
    cliente.direccion = this.formularioActualizar.controls.direccion.value;
    cliente.email = this.formularioActualizar.controls.email.value;
    cliente.celular = this.formularioActualizar.controls.celular.value;
    cliente.telefono = this.formularioActualizar.controls.telefono.value;

    this.request.putActualizarCliente(cliente).subscribe(

      data => {
        console.log(data);
        if (data.status) {
          this.evento.next('rerender');
          $('#modalMantClienteActualizar').modal('hide');
          const action = new fromCliente.EditarClienteAction(
            cliente.idCliente,
            cliente.tipoDocumento,
            cliente.numeroDocumento,
            cliente.denominacion,
            cliente.direccion,
            cliente.email,
            cliente.celular,
            cliente.telefono,
            cliente.habilitado
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
