import { Component, OnInit } from '@angular/core';
import { Venta } from '../../../../_modelos/venta.model';
import { VDetalle } from '../../../../_modelos/vdetalle.model';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { RequestService } from '../../../../_servicios/request.service';
import { Cliente } from '../../../../_modelos/cliente.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { WSConsultaDNI } from '../../../../_modelos/wsConsultaDNI.model';
import { WSConsultaRUC } from '../../../../_modelos/wsConsultaRUC.model';
import { SolAutorizacionDescuento } from '../../../../_modelos/solAutorizacionDescuento.model';
import { DocumentoVenta } from '../../../../_modelos/documentoventa.model';
import { AuthService } from '../../../../_servicios/auth.service';
import { Autenticacion } from '../../../../_modelos/autenticacion.model';
import { DatosInicialesVenta } from '../../../../_modelos/datosinicialesventa.model';
import { DocumentoVentaDetalle } from '../../../../_modelos/documentoventadetalle.model';
import { WSConsultaDNIAPI } from 'src/app/_modelos/wsConsultaDNIAPI.model';
import * as printJS from 'print-js';
import { AppSettings } from 'src/app/app.settings';
import { WSConsultaRUCAPI } from '../../../../_modelos/wsConsultaRUCAPI.model';
declare var kendo: any;
declare var $: any;

@Component({
  selector: 'app-venta-boleta',
  templateUrl: './venta-boleta.component.html',
  styleUrls: ['./venta-boleta.component.css']
})
export class VentaBoletaComponent implements OnInit {

  autenticacion: Autenticacion;
  autenticacionSubscription: Subscription;

  boleta: DocumentoVenta;
  datosInicialesBoleta: DatosInicialesVenta;

  formularioVendedor: FormGroup;
  formularioCliente: FormGroup;
  formularioVenta: FormGroup;
  clientes: Cliente[] = [];

  clienteModelValid: boolean;

  id: number;

  operando: boolean;
  emitido: boolean;

  public clienteModel: any;
  public productoModel: any;

  public FechaInicio: string = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  public FechaVencimiento: string = formatDate(new Date(), 'yyyy-MM-dd', 'en');

  cliente: Cliente;
  imprimirBoleta = () => {
    const url = 'http://eudora.vivienda.gob.pe/OBSERVATORIO/ZONIFICACION/Ate.pdf';
    console.log('print boleta22', url);
  }
  clienteSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      map(term => term === '' ? []
        : this.datosInicialesBoleta.clientes.filter(
          v => (
            v.numeroDocumento + ' ' +
            v.denominacion
          ).toLowerCase().indexOf(term.toLowerCase()) > -1
        ).slice(0, 10)
      )
    )

  productoSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      map(term => term === '' ? []
        : this.datosInicialesBoleta.productos.filter(
          v => (
            v.codigo + ' ' +
            v.nombre + ' ' +
            v.marca
          ).toLowerCase().indexOf(term.toLowerCase()) > -1
        )
      )
    )

  formatterCliente = (x: { numeroDocumento: string }) => x.numeroDocumento;
  formatterProducto = (x: { nombre: string }) => x.nombre;

  constructor(
    private request: RequestService,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {
    this.autenticacionSubscription = this.authService.autenticacion.subscribe(user => {
      this.autenticacion = user;
      console.log(this.autenticacion);
    });
  }

  buildFormCliente() {
    this.formularioCliente = this.formBuilder.group({
      tipoDocumento: ['1', [Validators.required]],
      numeroDocumento: ['', [Validators.pattern('^[0-9]*$')]],
      denominacion: ['', [Validators.pattern('^[a-zA-Z ]*$')]],
      email: ['', [Validators.email]],
      celular: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(7), Validators.maxLength(9)]]
    });
  }

  buildFormVendedor() {
    this.formularioVendedor = this.formBuilder.group({
      idJalador: [0, [Validators.required]],
      tipoRegalo: [0],
      cantidadRegalo: [0]
    });
  }

  buildFormVenta() {
    this.formularioVenta = this.formBuilder.group({
      fecha: [this.FechaInicio, [Validators.required]],
      moneda: ['1', [Validators.required]],
      igv: ['18%', [Validators.required]],
      condicion: ['1', [Validators.required]],
      numeroOperacion: ['']
    });
  }

  ngOnInit() {

    this.buildFormCliente();
    this.buildFormVenta();
    this.buildFormVendedor();

    this.datosInicialesBoleta = new DatosInicialesVenta();
    this.datosInicialesBoleta.jaladores = [];
    this.datosInicialesBoleta.clientes = [];
    this.datosInicialesBoleta.productos = [];

    this.inicializarBoleta();

    this.id = 0;
    this.clienteModelValid = true;

    this.operando = false;
    this.emitido = false;

    this.request.postDatosInicialesBoleta(this.autenticacion.idEmpleado).subscribe(
      respuesta => {
        this.datosInicialesBoleta = respuesta;
        this.boleta.serie = this.datosInicialesBoleta.serie;
        this.boleta.numero = this.datosInicialesBoleta.numero;
        console.log(this.datosInicialesBoleta);
      }
    );
  }

  changeCantidad(evento: any, idDocumentoVentaDetalle: number) {
    const detalle = this.boleta.detalle.find(det => det.idDocumentoVentaDetalle === idDocumentoVentaDetalle);
    detalle.descuento = 0;
    detalle.descuentoHabilitado = false;
    detalle.descuentoCodigo = '';
    detalle.descuentoValidado = false;
    detalle.descuentoPorcentaje = 0;

    detalle.subtotal = detalle.cantidad * detalle.productoValorUnitario;
    detalle.igv = (detalle.cantidad * (detalle.productoPrecioUnitario - detalle.productoValorUnitario));
    detalle.total = detalle.cantidad * detalle.productoPrecioUnitario;

    // Pie
    const totGravadas = this.boleta.detalle.map(det => det.subtotal);
    // const totDescuentos = this.boleta.detalle.map(det => det.descuentoPorcentaje);
    const totDescuentos = this.boleta.detalle.map(det => {
      if (det.descuentoValidado) {
        return det.descuento;
      } else {
        return 0;
      }
    });
    const totIgvs = this.boleta.detalle.map(det => det.igv);
    const tots = this.boleta.detalle.map(det => det.total);

    this.boleta.totalGravada = totGravadas.reduce(this.total);
    this.boleta.totalDescuento = totDescuentos.reduce(this.total);
    this.boleta.totalIGV = totIgvs.reduce(this.total);
    this.boleta.total = tots.reduce(this.total);
  }

  selectedItemProducto(evento: any, idDocumentoVentaDetalle: number) {
    const detalle = this.boleta.detalle.find(det => det.idDocumentoVentaDetalle === idDocumentoVentaDetalle);

    const productonombre: string = evento.item.nombre;

    // Detalle
    detalle.unidadMedida = 'NIU';
    detalle.productoId = evento.item.idProducto;
    detalle.productoNombre = productonombre;
    detalle.productoDescripcion = evento.item.descripcion;
    detalle.productoCodigo = evento.item.codigo;
    detalle.productoCodigoInterno = evento.item.codigo;
    detalle.productoValorUnitario = (evento.item.precioVenta / 1.18);
    detalle.productoPrecioUnitario = +evento.item.precioVenta;
    detalle.cantidad = 1;
    detalle.descuento = 0;
    detalle.descuentoHabilitado = false;
    detalle.descuentoCodigo = '';
    detalle.descuentoValidado = false;
    detalle.descuentoPorcentaje = 0;
    detalle.subtotal = detalle.cantidad * detalle.productoValorUnitario;
    detalle.igv = (detalle.cantidad * (detalle.productoPrecioUnitario - detalle.productoValorUnitario));
    detalle.total = detalle.cantidad * detalle.productoPrecioUnitario;

    // Pie
    const totGravadas = this.boleta.detalle.map(det => det.subtotal);
    // const totDescuentos = this.boleta.detalle.map(det => det.descuentoPorcentaje);
    const totDescuentos = this.boleta.detalle.map(det => {
      if (det.descuentoValidado) {
        return det.descuento;
      } else {
        return 0;
      }
    });
    const totIgvs = this.boleta.detalle.map(det => det.igv);
    const tots = this.boleta.detalle.map(det => det.total);

    this.boleta.totalGravada = totGravadas.reduce(this.total);
    this.boleta.totalDescuento = totDescuentos.reduce(this.total);
    this.boleta.totalIGV = totIgvs.reduce(this.total);
    this.boleta.total = tots.reduce(this.total);

    console.log(this.boleta);
  }

  selectedItemCliente(evento: any) {

    const num: string = evento.item.numeroDocumento;
    const tipoDocumento: number = this.formularioCliente.controls.tipoDocumento.value;
    const c = this.datosInicialesBoleta.clientes.filter(item => item.numeroDocumento === num)[0];
    this.formularioCliente.controls.numeroDocumento.setValue(c.numeroDocumento);
    this.formularioCliente.controls.denominacion.setValue(c.denominacion);
    this.boleta.clienteDenominacion = c.denominacion;
    this.boleta.clienteNumeroDocumento = c.numeroDocumento;

    if (num.length === 8 && +tipoDocumento === 1) {
      this.clienteModelValid = true;
    } else {
      if (num.length === 11 && +tipoDocumento === 2) {
        this.clienteModelValid = true;
      } else {
        this.clienteModelValid = false;
      }
    }
  }

  onSearchChange(evento: any) {
    const num = evento.target.value;
    const tipoDocumento: number = this.formularioCliente.controls.tipoDocumento.value;

    if (num.length === 1) {
      this.formularioCliente.controls.denominacion.setValue('');
      this.boleta.clienteNumeroDocumento = '';
      this.boleta.clienteDenominacion = '';
    }

    if (num.length === 8 && +tipoDocumento === 1) {
      this.clienteModelValid = true;
    } else {
      if (num.length === 11 && +tipoDocumento === 2) {
        this.clienteModelValid = true;
      } else {
        this.clienteModelValid = false;
      }
    }
  }

  private total(total: number, num: number) {
    return total + num;
  }

  agregarProductoDetalle() {
    this.id = this.id + 1;
    const detalle = new DocumentoVentaDetalle();
    detalle.idDocumentoVentaDetalle = this.id;
    detalle.cantidad = 0;
    detalle.productoCodigo = '';
    detalle.descuento = 0;
    detalle.descuentoHabilitado = false;
    detalle.descuentoCodigo = '';
    detalle.descuentoValidado = false;
    detalle.descuentoPorcentaje = 0;
    this.boleta.detalle.unshift(detalle);
  }

  eliminarProductoDetalle(idDocumentoVentaDetalle) {
    this.boleta.detalle = this.boleta.detalle.filter((obj) => {
      return obj.idDocumentoVentaDetalle !== idDocumentoVentaDetalle;
    });
    // Pie
    const totGravadas = this.boleta.detalle.map(det => det.subtotal);
    // const totDescuentos = this.boleta.detalle.map(det => det.descuento);
    const totDescuentos = this.boleta.detalle.map(det => {
      if (det.descuentoValidado) {
        return det.descuento;
      } else {
        return 0;
      }
    });
    const totIgvs = this.boleta.detalle.map(det => det.igv);
    const tots = this.boleta.detalle.map(det => det.total);
    this.boleta.totalGravada = totGravadas.reduce(this.total);
    this.boleta.totalDescuento = totDescuentos.reduce(this.total);
    this.boleta.totalIGV = totIgvs.reduce(this.total);
    this.boleta.total = tots.reduce(this.total);
  }

  emitirVentaModal() {

    this.prepararBoleta();

    console.log(this.boleta);

    const detalle = this.boleta.detalle.map(det => det.productoCodigo);
    const cantidad = this.boleta.detalle.map(det => det.cantidad);

    let errorCantidadProducto = false;
    let errorCodigoProducto = false;

    for (const producto of this.boleta.detalle) {
      if (producto.productoCodigo === '') {
        errorCodigoProducto = true;
        break;
      }

      if (producto.cantidad === 0) {
        errorCantidadProducto = true;
        break;
      }
    }

    if (!this.formularioVendedor.valid || !this.formularioCliente || !this.formularioVenta) {
      alert('Formularios inválidos');
      return;
    } else {
      if (this.boleta.detalle.length === 0) {
        alert('Debe ingresar al menos un producto');
        return;
      } else {
        if (this.boleta.tipoRegalo > 0 && this.boleta.cantidadRegalo === 0) {
          alert('Debe consignar una cantidad de regalos');
          return;
        } else {
          if (cantidad.reduce(this.total) < 1) {
            alert('Debe establecer una cantidad para cada producto en el detalle');
            return;
          } else {
            if (errorCodigoProducto) {
              alert('Debe ingresar todos los productos requeridos en el detalle');
              return;
            } else {
              if (errorCantidadProducto) {
                alert('Todas las cantidades del detalle deben ser mayores a cero');
              } else {
                if (this.formularioVenta.controls.condicion.value === '2' && this.formularioVenta.controls.numeroOperacion.value === '') {
                  alert('Debe consignar el numero de operacion');
                  return;
                } else {
                  if (+this.boleta.clienteTipoDocumento === 2 && +this.boleta.clienteNumeroDocumento.length !== 11) {
                    alert('El RUC del cliente es inválido');
                    return;
                  } else {
                    if (+this.boleta.clienteTipoDocumento === 1 && +this.boleta.clienteNumeroDocumento.length !== 8) {
                      alert('El DNI del cliente es inválido');
                      return;
                    } else {
                      if (this.boleta.clienteDenominacion === '') {
                        alert('El cliente es inválido');
                        return;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    this.emitido = false;
    this.operando = false;
    $('#modalBoletaVenta').modal({ backdrop: 'static', keyboard: false });
    $('#modalBoletaVenta').modal('show');
    this.emitirVenta();
  }

  emitirVenta() {

    console.log(this.boleta);

    this.operando = true;

    this.request.postEmitirComprobante(this.boleta).subscribe(
      res => {
        console.log(res);
        if (res.message === 'El correlativo ya existe') {
          $('#modalBoletaVenta').modal('hide');
          $('.modal-backdrop').remove();
          $('#modalBoletaVenta').css({display: 'none'});
          $('body').removeClass('modal-open');
          alert('El correlativo ya fue usado, se ha actualizado al siguiente numero, por favor reintentar.');
          this.boleta.numero = res.data.Numero;
        } else {
          if (!res.status) {
            $('#modalBoletaVenta').modal('hide');
            alert('Ocurrió un problema generando el comprobante \n' + res.message);
          } else {
            this.operando = false;
            this.emitido = true;
          }
        }
      }
    );

  }

  cerrarModal() {
    this.inicializarBoleta();
    this.id = 0;
    this.clienteModelValid = true;

    this.request.postDatosInicialesBoleta(this.autenticacion.idEmpleado).subscribe(
      respuesta => {
        this.datosInicialesBoleta = respuesta;
        this.boleta.serie = this.datosInicialesBoleta.serie;
        this.boleta.numero = this.datosInicialesBoleta.numero;
        console.log(this.datosInicialesBoleta);
        this.formularioVendedor.controls.tipoRegalo.setValue(0);
        this.formularioVendedor.controls.cantidadRegalo.setValue(0);
        this.formularioVendedor.controls.idJalador.setValue(0);
        this.formularioCliente.controls.tipoDocumento.setValue(1);
        this.formularioCliente.controls.numeroDocumento.setValue('');
        this.formularioCliente.controls.denominacion.setValue('');
        this.formularioCliente.controls.email.setValue('');
        this.formularioCliente.controls.celular.setValue('');
        this.formularioVenta.controls.condicion.setValue(1);
        this.formularioVenta.controls.numeroOperacion.setValue('');
        $('#numeroOperacion').attr('readonly', true);
        console.log(this.boleta);
      }
    );
    $('#modalBoletaVenta').modal('hide');
  }

  consultarWS() {
    const dato = this.formularioCliente.controls.numeroDocumento.value;
    const tipo = this.formularioCliente.controls.tipoDocumento.value;

    console.log(dato);
    console.log(tipo);

    if (tipo === '1') {
      let wsDNIAPI: WSConsultaDNIAPI;
      this.request.postConsultarDNI(dato).subscribe(
        res => {
          if (!res.success) {
            alert('DNI no encontrado');
          } else {
            wsDNIAPI = res;
            this.boleta.clienteNumeroDocumento = dato;
            this.boleta.clienteDenominacion = wsDNIAPI.result.NombreCompleto;
            this.formularioCliente.controls.denominacion.setValue(wsDNIAPI.result.NombreCompleto);
          }
        },
        err => {
          this.request.getConsultarDNI(dato).subscribe(
            res => {
              this.boleta.clienteNumeroDocumento = dato;
              this.boleta.clienteDenominacion = res.nombres + ' ' + res.apellidoPaterno + ' ' + res.apellidoMaterno;
              this.formularioCliente.controls.denominacion.setValue(res.nombres + ' ' + res.apellidoPaterno + ' ' + res.apellidoMaterno);
            }
          );
        }
      );
    } else {
      let wsRUCAPI: WSConsultaRUCAPI;
      this.request.postConsultarRUC(dato).subscribe(
        res => {
          if (!res.success) {
            alert('RUC no encontrado');
          } else {
            wsRUCAPI = res;
            this.boleta.clienteNumeroDocumento = dato;
            this.boleta.clienteDenominacion = wsRUCAPI.result.RazonSocial;
            this.formularioCliente.controls.denominacion.setValue(wsRUCAPI.result.RazonSocial);
          }
        },
        err => {
          this.request.getConsultarRUC(dato).subscribe(
            res => {
              this.boleta.clienteNumeroDocumento = dato;
              this.boleta.clienteDenominacion = res.razonSocial;
              this.formularioCliente.controls.denominacion.setValue(res.razonSocial);
            }
          );
        }
      );
    }

  }

  solicitarAutorizacion(detalle: DocumentoVentaDetalle) {

    console.log(detalle.subtotal, detalle.descuentoPorcentaje);

    if (!detalle.productoCodigo) {
      alert('Debe ingresar un producto para solicituar un descuento');
      return;
    } else {
      if (detalle.cantidad < 1) {
        alert('Debe ingresar una cantidad válida');
        return;
      } else {
        if (detalle.descuentoPorcentaje < 1) {
          alert('Debe ingresar una monto a descontar');
          return;
        } else {
          if (detalle.descuentoPorcentaje >= detalle.subtotal) {
            alert('La cantidad a descontar debe ser menor que el subtotal');
            return;
          }
        }
      }
    }

    const solicitud = new SolAutorizacionDescuento(
      this.autenticacion.idUsuario,
      this.boleta.serie + '-' + this.boleta.numero,
      detalle.descuentoPorcentaje,
      detalle.productoCodigo,
      detalle.cantidad,
      '');

    this.request.postSolicitarAutorizacion(solicitud).subscribe(
      res => {
        console.log(res);
        if (res.status) {
          alert('Se envió un correo con la autorización');
        }
      }
    );
  }

  validarAutorizacion(detalle: DocumentoVentaDetalle) {
    const solicitud = new SolAutorizacionDescuento(
      this.autenticacion.idUsuario,
      this.boleta.serie + '-' + this.boleta.numero,
      detalle.descuentoPorcentaje,
      detalle.productoCodigo,
      detalle.cantidad,
      detalle.descuentoCodigo);

    const item = this.boleta.detalle.find(i => i.idDocumentoVentaDetalle === detalle.idDocumentoVentaDetalle);
    console.log(item);
    this.request.postValidarAutorizacion(solicitud).subscribe(
      res => {
        console.log(res);
        if (res === 'Exito') {
          item.descuentoValidado = true;
          item.descuentoHabilitado = false;
          item.descuento = detalle.descuentoPorcentaje / 1.18;
          // Pie

          item.subtotal = item.subtotal - detalle.descuento;
          item.total = item.subtotal * 1.18;
          item.igv = item.total - item.subtotal;

          const totGravadas = this.boleta.detalle.map(det => det.subtotal);
          const totDescuentos = this.boleta.detalle.map(det => {
            if (det.descuentoValidado) {
              return det.descuento;
            } else {
              return 0;
            }
          });
          const totIgvs = this.boleta.detalle.map(det => det.igv);
          const tots = this.boleta.detalle.map(det => det.total);

          console.log(totGravadas);
          console.log(totDescuentos);
          console.log(totIgvs);
          console.log(tots);

          this.boleta.totalGravada = totGravadas.reduce(this.total);
          this.boleta.totalDescuento = totDescuentos.reduce(this.total);
          this.boleta.totalIGV = totIgvs.reduce(this.total);
          this.boleta.total = tots.reduce(this.total);

          console.log(this.boleta);
        }
      }
    );
  }

  generarpdf() {
    this.request.crearPDF(this.boleta).subscribe((res: string) => {
      const urlPDF = AppSettings.WEB_API + res;
      printJS(urlPDF);
    }, error => {
      alert('El servidor no reponde');
    });
  }

  cancelarVenta() {
    if (confirm('Esta seguro que desea cancelar la venta actual? los datos se borrarán.')) {
      this.cerrarModal();
    } else {
      console.log('Selecciono NO');
    }
  }

  prepararBoleta() {
    // Poblamos el Documento de Venta
    this.boleta.idUsuario = this.autenticacion.idUsuario;
    this.boleta.idVendedor = this.autenticacion.idEmpleado;
    this.boleta.idJalador = this.formularioVendedor.controls.idJalador.value;
    this.boleta.tipoRegalo = this.formularioVendedor.controls.tipoRegalo.value;
    this.boleta.cantidadRegalo = this.formularioVendedor.controls.cantidadRegalo.value;
    this.boleta.condicionPago = this.formularioVenta.controls.condicion.value;
    this.boleta.numeroOperacion = this.formularioVenta.controls.numeroOperacion.value;
    this.boleta.clienteTipoDocumento = this.formularioCliente.controls.tipoDocumento.value;
    this.boleta.clienteDireccion = (this.formularioCliente.controls.direccion) ?
      this.formularioCliente.controls.direccion.value : '';
    this.boleta.clienteEmail = (this.formularioCliente.controls.email) ?
      this.formularioCliente.controls.email.value : '';
    this.boleta.clienteCelular = (this.formularioCliente.controls.celular) ?
      this.formularioCliente.controls.celular.value : '';
    this.boleta.enviarSunat = false;
    this.boleta.enviarCliente = false;

    this.boleta.clienteNumeroDocumento = (this.boleta.clienteNumeroDocumento === '') ? '11111111' : this.boleta.clienteNumeroDocumento;
    this.boleta.clienteDenominacion = (this.boleta.clienteDenominacion === '') ? 'VARIOS' : this.boleta.clienteDenominacion;
    this.boleta.clienteDireccion = (this.boleta.clienteDireccion === '') ? 'LIMA' : this.boleta.clienteDireccion;
  }

  inicializarBoleta() {
    this.boleta = new DocumentoVenta();
    this.boleta.detalle = [];
    this.boleta.tipoDocumento = '2';
    this.boleta.fecha = this.formularioVenta.controls.fecha.value;
    this.boleta.moneda = this.formularioVenta.controls.moneda.value;
    this.boleta.igv = 18;
    this.boleta.totalDescuento = 0;
    this.boleta.totalGravada = 0;
    this.boleta.totalIGV = 0;
    this.boleta.total = 0;
    this.boleta.clienteTipoDocumento = 1;
    this.boleta.clienteNumeroDocumento = '';
    this.boleta.clienteDenominacion = '';
    this.boleta.numeroOperacion = '';
  }
}
