import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { Empleado } from '../../../_modelos/empleado.model';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import { RequestService } from '../../../_servicios/request.service';
import { Observable } from 'rxjs';
import * as fromEmpleado from './../empleado.actions';

declare var $: any;

@Component({
  selector: 'app-mant-empleado-eliminar',
  templateUrl: './mant-empleado-eliminar.component.html',
  styleUrls: ['./mant-empleado-eliminar.component.css']
})
export class MantEmpleadoEliminarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  @Input() idEmpleado: Observable<any>;
  empleados: Empleado[] = [];
  operando: boolean;
  public empleado: Empleado = null;

  constructor(
    private store: Store<AppState>,
    private request: RequestService
  ) { }

  seleccionar(idEmpleado: number) {
    this.empleado = JSON.parse(JSON.stringify(this.empleados)).find(i => i.idEmpleado === idEmpleado);
    console.log(this.empleado);
  }

  ngOnInit() {
    this.operando = false;
    this.store.subscribe(
      state => {
        this.empleados = state.empleados;
        console.log(this.empleados);
      }
    );
  }

  eliminarEmpleado(idEmpleado: number) {
    this.operando = true;
    const empleado = new Empleado(idEmpleado);
    this.request.putEliminarEmpleado(empleado).subscribe(

      data => {
        console.log(data);
        if (data.status) {
          this.evento.next('rerender');
          $('#modalMantEmpleadoEliminar').modal('hide');
          const action = new fromEmpleado.BorrarEmpleadoAction(
            idEmpleado
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }

    );
  }

}
