import { Producto } from './producto.model';
export class VDetalle {
  idDetalle: number;
  producto: Producto;
  cantidad: number;
  unidad: number;
  valorUnitario: number;
  precioUnitario: number;
  importeTotal: number;

  constructor(
    idDetalle: number,
    producto: Producto,
    cantidad: number,
    unidad: number,
    valorUnitario: number,
    precioUnitario: number,
    importeTotal: number
  ){
    this.idDetalle = idDetalle;
    this.producto = producto;
    this.cantidad = cantidad;
    this.unidad = unidad;
    this.valorUnitario = valorUnitario;
    this.precioUnitario = precioUnitario;
    this.importeTotal = importeTotal;
  }
}
