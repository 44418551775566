import { Component, OnInit } from '@angular/core';
import { Autenticacion } from '../../_modelos/autenticacion.model';
import { Menu } from '../../_modelos/menu.model';
import { AuthService } from '../../_servicios/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-sidebar',
  templateUrl: './page-sidebar.component.html',
  styleUrls: ['./page-sidebar.component.css']
})
export class PageSidebarComponent implements OnInit {

  public autenticacion: Autenticacion;
  private menu: Menu;
  private autenticacionSubscription: Subscription;

  constructor( private authService: AuthService) {
    this.autenticacionSubscription = this.authService.autenticacion.subscribe(user => {
      this.autenticacion = user;
    });
  }

  ngOnInit() {
    console.log(this.autenticacion);
  }

}
