import { Component, OnInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { RequestService } from '../../../_servicios/request.service';
import { Autenticacion } from '../../../_modelos/autenticacion.model';
import { Subscription, Subject } from 'rxjs';
import { DocumentoVenta } from '../../../_modelos/documentoventa.model';
import { AuthService } from '../../../_servicios/auth.service';
import { DataTableDirective } from 'angular-datatables';
import { formatDate } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-ventas-reporte',
  templateUrl: './ventas-reporte.component.html',
  styleUrls: ['./ventas-reporte.component.css']
})
export class VentasReporteComponent implements OnInit {

  @ViewChildren('registros') things: QueryList<any>;

  dtOptions: any = {};
  dtTrigger: Subject<null> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  autenticacion: Autenticacion;
  autenticacionSubscription: Subscription;
  ventas: DocumentoVenta[] = [];

  constructor(
    private request: RequestService,
    private authService: AuthService
  ) {
    this.autenticacionSubscription = this.authService.autenticacion.subscribe(user => {
      this.autenticacion = user;
      console.log(this.autenticacion);
    });
  }

  ngOnInit() {

    this.dtOptions = {
      initComplete: () => {
        $('div.dataTables_filter input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          text: 'Excel',
          title: 'Reporte de Ventas - ' + this.autenticacion.idUsuario + ' - ' + formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          footer: true
        },
        {
          extend: 'pdfHtml5',
          text: 'PDF',
          title: 'Reporte de Ventas - ' + this.autenticacion.idUsuario + ' - ' + formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          footer: true
        },
        {
          extend: 'print',
          text: 'Imprimir',
          title: 'Reporte de Ventas - ' + this.autenticacion.idUsuario + ' - ' + formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          footer: true
        }
      ],
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_ registros',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: 'Siguiente',
          sPrevious: 'Anterior'
        },
        oAria: {
          sSortAscending: ': Activar para ordenar la columna de manera ascendente',
          sSortDescending: ': Activar para ordenar la columna de manera descendente'
        }
      }
    };

    this.request.getVentasUsuario(this.autenticacion.idUsuario).subscribe(
      resp => {
        console.log(resp);
        this.ventas = resp;
        this.dtTrigger.next();
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

}
