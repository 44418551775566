import * as fromCliente from './cliente.actions';
import { Cliente } from '../../_modelos/cliente.model';

const estadoInicial: Cliente[] = [];

export function clienteReducer(
  state = estadoInicial,
  action: fromCliente.Acciones): Cliente[] {
  switch (action.type) {
    case fromCliente.INICIALIZAR_TODO_CLIENTE:
      let clientes = [...state];
      clientes = action.clientes;
      return clientes;
    case fromCliente.AGREGAR_CLIENTE:
      const cliente = new Cliente(
        action.idCliente,
        action.tipoDocumento,
        action.numeroDocumento,
        action.denominacion,
        action.direccion,
        action.email,
        action.celular,
        action.telefono,
        action.habilitado
      );
      return [...state, cliente];
    case fromCliente.EDITAR_CLIENTE:
      return state.map(clienteEdit => {

        if (clienteEdit.idCliente === action.idCliente) {
          return {
            ...clienteEdit,
            tipoDocumento: action.tipoDocumento,
            numeroDocumento: action.numeroDocumento,
            denominacion: action.denominacion,
            direccion: action.direccion,
            email: action.email,
            celular: action.celular,
            telefono: action.telefono,
            habilitado: action.habilitado
          };
        } else {
          return clienteEdit;
        }
      });
    case fromCliente.BORRAR_CLIENTE:
      return state.filter(clienteEdit => clienteEdit.idCliente !== action.idCliente);
    default:
      return state;
  }
}
