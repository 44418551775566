export class Local {
  idLocal: number;
  nombre: string;
  descripcion: string;
  direccion: string;
  distrito: string;
  provincia: string;
  departamento: string;
  aforo: number;
  habilitado: number;

  constructor(
    idLocal?: number,
    nombre?: string,
    descripcion?: string,
    direccion?: string,
    distrito?: string,
    provincia?: string,
    departamento?: string,
    aforo?: number,
    habilitado?: number
  ) {
    this.idLocal = idLocal;
    this.nombre = nombre;
    this.descripcion = descripcion;
    this.direccion = direccion;
    this.distrito = distrito;
    this.provincia = provincia;
    this.departamento = departamento;
    this.aforo = aforo;
    this.habilitado = habilitado;
  }
}
