import { Component, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { AuthService } from '../../_servicios/auth.service';
import { RequestService } from '../../_servicios/request.service';
import { Subscription, Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Autenticacion } from '../../_modelos/autenticacion.model';

@Component({
  selector: 'app-reporte-liquidacionv',
  templateUrl: './reporte-liquidacionv.component.html',
  styleUrls: ['./reporte-liquidacionv.component.css']
})
export class ReporteLiquidacionvComponent implements OnInit {

  dtOptions: any = {};
  dtTrigger: Subject<null> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  autenticacion: Autenticacion;
  autenticacionSubscription: Subscription;

  reporte: any;

  constructor(
    private request: RequestService,
    private authService: AuthService
  ) {
    this.autenticacionSubscription = this.authService.autenticacion.subscribe(user => {
      this.autenticacion = user;
      console.log(this.autenticacion);
    });
  }

  ngOnInit() {
    this.request.getReporteLiquidacionMaestro(this.autenticacion.idUsuario).subscribe(
      res => {
        console.log(res);
        this.reporte = res;
        this.dtTrigger.next();
      }
    );

    this.dtOptions = {
      initComplete: () => {
        $('div.dataTables_filter input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        // { extend: 'colvis', text: 'Visibilidad Columnas'},
        { extend: 'copy', text: 'Copiar', title: 'Reporte de Liquidacion Maestro - ' + formatDate(new Date(), 'yyyyMMdd', 'en') },
        {
          extend: 'print',
          text: 'Imprimir',
          title: 'Reporte de Liquidacion Maestro - ' + formatDate(new Date(), 'yyyyMMdd', 'en'),
          customize: ( win ) => {
            $(win.document.body)
                .css( 'font-size', '10pt' );
            $(win.document.body).find( 'table' )
                .addClass( 'compact' )
                .css( { 'font-weight': 'bold', 'color': '#000'} );
        }
        },
        { extend: 'excel', text: 'Excel', title: 'Reporte de Liquidacion Maestro - ' + formatDate(new Date(), 'yyyyMMdd', 'en') }
      ],
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_ registros',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: 'Siguiente',
          sPrevious: 'Anterior'
        },
        oAria: {
          sSortAscending: ': Activar para ordenar la columna de manera ascendente',
          sSortDescending: ': Activar para ordenar la columna de manera descendente'
        }
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
      columnDefs: {

      }
    };
  }

  filterById(): void {
    console.log('Entro');
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  public reload(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
