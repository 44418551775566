import { Component, OnInit } from '@angular/core';
import { Asignacion } from '../../_modelos/asignacion.model';
import { Empleado } from '../../_modelos/empleado.model';
import { RequestService } from '../../_servicios/request.service';
import { Cliente } from '../../_modelos/cliente.model';
import { Local } from '../../_modelos/local.model';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-asignacion',
  templateUrl: './asignacion.component.html',
  styleUrls: ['./asignacion.component.css']
})
export class AsignacionComponent implements OnInit {

  asignacion: Asignacion[];

  public vendedorModel: any;
  public clienteModel: any;
  public localModel: any;

  vendedores: Empleado[] = [];
  jaladores: Empleado[] = [];
  locales: Local[] = [];

  vendedorSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      map(term => term === '' ? []
        : this.vendedores.filter(
          v => (
            v.numeroDocumento + ' ' +
            v.nombres + ' ' +
            v.apellidoPaterno + ' ' +
            v.apellidoMaterno
          ).toLowerCase().indexOf(term.toLowerCase()) > -1
        ).slice(0, 10)
      )
    )

  jaladorSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      map(term => term === '' ? []
        : this.jaladores.filter(
          j => (
            j.numeroDocumento + ' ' +
            j.nombres + ' ' +
            j.apellidoPaterno + ' ' +
            j.apellidoMaterno
          ).toLowerCase().indexOf(term.toLowerCase()) > -1
        ).slice(0, 10)
      )
    )

  formatter = (x: {
    nombres: string,
    apellidoPaterno: string,
    apellidoMaterno: string
  }) => x.nombres + ' ' + x.apellidoPaterno + ' ' + x.apellidoMaterno;


  constructor(private request: RequestService) { }

  ngOnInit() {
    this.request.getPrepararAsignacion().subscribe(
      res => {
        console.log(res);
        this.vendedores = res.vendedores;
        this.jaladores = res.jaladores;
        this.locales = res.locales;
        this.asignacion = res.asignacion;
      }
    );
  }

  public agregarAsignacion() {
    const nuevaAsignacion: Asignacion = new Asignacion(0, null, null, 1, null);
    this.asignacion.unshift(nuevaAsignacion);
  }

  // public agregarJalador(index: number) {
  //   const nuevoJalador: Empleado = new Empleado();
  //   this.asignacion[index].jaladores.push(nuevoJalador);
  // }

  public borrarJalador(empleado: number) {
    console.log(empleado);
    this.asignacion[empleado].idJalador = 0;
  }

  public borrarEmpleado(indexEmpleado: number) {
    this.asignacion.splice(indexEmpleado, 1);
  }

  public guardar() {
    this.request.postGuardarAsignacion(this.asignacion).subscribe(
      res => {
        console.log(res);
        alert('Datos Guardados con éxito');
        this.asignacion = res.data.asignacion;
      }
    );
  }

}
