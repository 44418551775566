import * as fromVentaDetalle from './ventadetalle.actions';
import { VDetalle } from '../../../_modelos/vdetalle.model';

const estadoInicialVDet: VDetalle[] = [];

export function ventaDetalleReducer(
  state = estadoInicialVDet,
  action: fromVentaDetalle.Acciones): VDetalle[] {
  switch (action.type) {
    case fromVentaDetalle.AGREGAR_VENTA_DETALLE:
      const ventaDetalle: VDetalle = new VDetalle(
        action.idDetalle,
        action.producto,
        action.cantidad,
        action.unidad,
        action.valorUnitario,
        action.precioUnitario,
        action.importeTotal
      );
      return [...state, ventaDetalle];
    case fromVentaDetalle.BORRAR_VENTA_DETALLE:
      return state.filter( detalleEdit => detalleEdit.idDetalle !== action.idDetalle);
    default:
      return state;
  }
}
