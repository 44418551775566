import * as fromLocal from './local.actions';
import { Local } from '../../_modelos/local.model';

const estadoInicial: Local[] = [];

export function localReducer(
  state = estadoInicial,
  action: fromLocal.Acciones): Local[] {
  switch (action.type) {
    case fromLocal.INICIALIZAR_TODO_LOCAL:
      let locales = [...state];
      locales = action.locales;
      return locales;
    case fromLocal.AGREGAR_LOCAL:
      const local = new Local(
        action.idLocal,
        action.nombre,
        action.descripcion,
        action.direccion,
        action.distrito,
        action.provincia,
        action.departamento,
        action.aforo,
        action.habilitado
      );
      return [...state, local];
    case fromLocal.EDITAR_LOCAL:
      return state.map(localEdit => {

        if (localEdit.idLocal === action.idLocal) {
          return {
            ...localEdit,
            nombre: action.nombre,
            descripcion: action.descripcion,
            direccion: action.direccion,
            distrito: action.distrito,
            provincia: action.provincia,
            departamento: action.departamento,
            aforo: action.aforo
          };
        } else {
          return localEdit;
        }

      });
      case fromLocal.BORRAR_LOCAL:
          return state.filter(localEdit => localEdit.idLocal !== action.idLocal);
    default:
      return state;
  }
}
