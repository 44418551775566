import { Component, OnInit, QueryList, ViewChildren, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { RequestService } from '../../../_servicios/request.service';
import { Empleado } from '../../../_modelos/empleado.model';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import * as fromEmpleado from './../empleado.actions';
import { MantEmpleadoInsertarComponent } from '../mant-empleado-insertar/mant-empleado-insertar.component';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { MantEmpleadoActualizarComponent } from '../mant-empleado-actualizar/mant-empleado-actualizar.component';
import { MantEmpleadoEliminarComponent } from '../mant-empleado-eliminar/mant-empleado-eliminar.component';

declare var $: any;

@Component({
  selector: 'app-mant-empleado-home',
  templateUrl: './mant-empleado-home.component.html',
  styleUrls: ['./mant-empleado-home.component.css']
})
export class MantEmpleadoHomeComponent implements OnInit, OnDestroy {

  @ViewChild('modalInsertarEmpleado', { static: false }) modalInsertar: MantEmpleadoInsertarComponent;
  @ViewChild(MantEmpleadoActualizarComponent, {static: false}) actualizarModal: MantEmpleadoActualizarComponent ;
  @ViewChild(MantEmpleadoEliminarComponent, {static: false}) eliminarModal: MantEmpleadoEliminarComponent ;

  empleados: Empleado[] = [];
  empleadoSelected: any;

  dtOptions: any = {};
  dtTrigger: Subject<null> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  constructor(
    private requestService: RequestService,
    private store: Store<AppState>
  ) {
    this.requestService.getListarEmpleados().subscribe(
      empleados => {
        const action = new fromEmpleado.InicializarEmpleadoAction(empleados);
        this.dtTrigger.next();
        $('.gj-overlay-loading').hide();
        this.store.dispatch(action);
      }
    );
  }

  ngOnInit() {
    this.dtOptions = {
      initComplete: () => {
        $('div.dataTables_filter input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'copy', text: 'Copiar', title: 'Grupo Jaramillo - Mantenimiento - Empleados' },
        { extend: 'print', text: 'Imprimir', title: 'Grupo Jaramillo - Mantenimiento - Empleados' },
        { extend: 'excel', text: 'Excel', title: 'Grupo Jaramillo - Mantenimiento - Empleados' }
      ],
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_ registros',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: 'Siguiente',
          sPrevious: 'Anterior'
        },
        oAria: {
          sSortAscending: ': Activar para ordenar la columna de manera ascendente',
          sSortDescending: ': Activar para ordenar la columna de manera descendente'
        }
      }
    };

    this.empleadoSelected = 0;
    this.store.subscribe(
      state => {
        this.empleados = state.empleados;
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public insertarEmpleado() {
    $('#modalMantEmpleadoInsertar').modal('show');
  }

  public editarEmpleado(idEmpleado: number) {
    this.actualizarModal.seleccionar(idEmpleado);
    $('#modalMantEmpleadoActualizar').modal('show');
  }

  public eliminarEmpleado(idEmpleado: number) {
    this.eliminarModal.seleccionar(idEmpleado);
    $('#modalMantEmpleadoEliminar').modal('show');
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
}
