import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Local } from 'src/app/_modelos/local.model';
import { RequestService } from '../../../_servicios/request.service';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import * as fromLocal from './../local.actions';

declare var $: any;

@Component({
  selector: 'app-mant-local-insertar',
  templateUrl: './mant-local-insertar.component.html',
  styleUrls: ['./mant-local-insertar.component.css']
})
export class MantLocalInsertarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  formularioInsertar: FormGroup;
  operando: boolean;

  constructor(
    private requestService: RequestService,
    private store: Store<AppState>,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.operando = false;
    this.buildForm();
  }

  buildForm() {
    this.formularioInsertar = this.formBuilder.group({
      nombre: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      descripcion: [null, [Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      direccion: [null],
      departamento: [null],
      provincia: [null],
      distrito: [null],
      aforo: [null]
    });
  }

  exit() {
    this.formularioInsertar.reset();
    $('#modalMantLocalInsertar').modal('hide');
  }

  validarFormulario() {
    if (this.formularioInsertar.controls.nombre.invalid) { this.formularioInsertar.controls.nombre.markAsDirty(); }
    if (this.formularioInsertar.controls.descripcion.invalid) { this.formularioInsertar.controls.descripcion.markAsDirty(); }
  }

  agregarLocal() {

    if (this.formularioInsertar.invalid) {
      alert('Formulario con errores');
      this.validarFormulario();
      return;
    }

    this.operando = true;

    const local = new Local();
    local.nombre = this.formularioInsertar.controls.nombre.value;
    local.descripcion = this.formularioInsertar.controls.descripcion.value;
    local.direccion = this.formularioInsertar.controls.direccion.value;
    local.departamento = this.formularioInsertar.controls.departamento.value;
    local.provincia = this.formularioInsertar.controls.provincia.value;
    local.distrito = this.formularioInsertar.controls.distrito.value;
    local.aforo = this.formularioInsertar.controls.aforo.value;

    this.requestService.postAgregarLocal(local).subscribe(

      data => {
        console.log(data);

        if(data.status) {
          this.evento.next('rerender');
          $('#modalMantLocalInsertar').modal('hide');
          const action = new fromLocal.AgregarLocalAction(
            data.data,
            local.nombre,
            local.descripcion,
            local.direccion,
            local.distrito,
            local.provincia,
            local.departamento,
            local.aforo,
            1
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
