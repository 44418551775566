import * as fromCategoria from './categoria.actions';
import { Categoria } from '../../_modelos/categoria.model';

const estadoInicial: Categoria = new Categoria(0, '', '', 0);
const estadoInicialCategorias: Categoria[] = [];

export function categoriaReducer(
  state = estadoInicialCategorias,
  action: fromCategoria.Acciones): Categoria[] {
  switch (action.type) {
    case fromCategoria.INICIALIZAR_TODO_CATEGORIA:
      let categorias = [...state];
      categorias = action.categorias;
      return categorias;
    case fromCategoria.AGREGAR_CATEGORIA:
      const categoria = new Categoria(
        action.idCategoria,
        action.nombre,
        action.descripcion,
        action.habilitado
      );
      return [...state, categoria];
    case fromCategoria.EDITAR_CATEGORIA:
      return state.map(categoriaEdit => {
        if (categoriaEdit.idCategoria === action.idCategoria) {
          return {
            ...categoriaEdit,
            nombre: action.nombre,
            descripcion: action.descripcion
          };
        } else {
          return categoriaEdit;
        }
      });
      case fromCategoria.BORRAR_CATEGORIA:
          return state.filter(categoriaEdit => categoriaEdit.idCategoria !== action.idCategoria);
    default:
      return state;
  }
}
