import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usuario-perfil',
  templateUrl: './usuario-perfil.component.html',
  styleUrls: ['./usuario-perfil.component.css']
})
export class UsuarioPerfilComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
