import { Component, OnInit, QueryList, ViewChildren, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { Cliente } from '../../../_modelos/cliente.model';
import { RequestService } from '../../../_servicios/request.service';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import * as fromCliente from './../cliente.actions';
import { MantClienteInsertarComponent } from '../mant-cliente-insertar/mant-cliente-insertar.component';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { MantClienteActualizarComponent } from '../mant-cliente-actualizar/mant-cliente-actualizar.component';
import { MantClienteEliminarComponent } from '../mant-cliente-eliminar/mant-cliente-eliminar.component';

declare var $: any;

@Component({
  selector: 'app-mant-cliente-home',
  templateUrl: './mant-cliente-home.component.html',
  styleUrls: ['./mant-cliente-home.component.css']
})
export class MantClienteHomeComponent implements OnDestroy, OnInit {

  @ViewChild('modalInsertarCliente', { static: false }) modalInsertar: MantClienteInsertarComponent;

  clientes: Cliente[] = [];
  clienteSelected: any;

  dtOptions: any = {};
  dtTrigger: Subject<null> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @ViewChild(MantClienteActualizarComponent, {static: false}) actualizarModal: MantClienteActualizarComponent ;
  @ViewChild(MantClienteEliminarComponent, {static: false}) eliminarModal: MantClienteEliminarComponent ;

  constructor(
    private requestService: RequestService,
    private store: Store<AppState>
  ) {
    this.requestService.getListarClientes().subscribe(
      clientes => {
        const action = new fromCliente.InicializarClienteAction(clientes);
        this.dtTrigger.next();
        $('.gj-overlay-loading').hide();
        this.store.dispatch(action);
      }
    );
  }

  ngOnInit() {
    this.dtOptions = {
      initComplete: () => {
        $('div.dataTables_filter input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'copy', text: 'Copiar', title: 'Grupo Jaramillo - Mantenimiento - Clientes' },
        { extend: 'print', text: 'Imprimir', title: 'Grupo Jaramillo - Mantenimiento - Clientes' },
        { extend: 'excel', text: 'Excel', title: 'Grupo Jaramillo - Mantenimiento - Clientes' }
      ],
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_ registros',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: 'Siguiente',
          sPrevious: 'Anterior'
        },
        oAria: {
          sSortAscending: ': Activar para ordenar la columna de manera ascendente',
          sSortDescending: ': Activar para ordenar la columna de manera descendente'
        }
      }
    };

    this.clienteSelected = 0;
    this.store.subscribe(
      state => {
        this.clientes = state.clientes;
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public insertarCliente() {
    $('#modalMantClienteInsertar').modal('show');
  }

  public editarCliente(idCliente: number) {
    this.actualizarModal.seleccionar(idCliente);
    $('#modalMantClienteActualizar').modal('show');
  }

  public eliminarCliente(idCliente: number) {
    this.eliminarModal.seleccionar(idCliente);
    $('#modalMantClienteEliminar').modal('show');
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

}
