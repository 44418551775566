import * as fromEmpleado from './empleado.actions';
import { Empleado } from '../../_modelos/empleado.model';

const estadoInicial: Empleado[] = [];

export function empleadoReducer(
  state = estadoInicial,
  action: fromEmpleado.Acciones): Empleado[] {
  switch (action.type) {
    case fromEmpleado.INICIALIZAR_TODO_EMPLEADO:
      let empleados = [...state];
      empleados = action.empleados;
      return empleados;
    case fromEmpleado.AGREGAR_EMPLEADO:
      const empleado = new Empleado(
        action.idEmpleado,
        action.nombres,
        action.apellidoPaterno,
        action.apellidoMaterno,
        action.fechaNacimiento,
        action.foto,
        action.direccion,
        action.telefono,
        action.celular,
        action.numberoDocumento,
        action.idTipoDocumento,
        action.idLocalEmpresa,
        action.habilitado,
        action.idCargo,
        action.usuario
      );
      return [...state, empleado];
    case fromEmpleado.EDITAR_EMPLEADO:
      return state.map(empleadoEdit => {

        if (empleadoEdit.idEmpleado === action.idEmpleado) {
          return {
            ...empleadoEdit,
            nombres: action.nombres,
            apellidoPaterno: action.apellidoPaterno,
            apellidoMaterno: action.apellidoMaterno,
            fechaNacimiento: action.fechaNacimiento,
            foto: action.foto,
            direccion: action.direccion,
            telefono: action.telefono,
            celular: action.celular,
            numberoDocumento: action.numberoDocumento,
            idTipoDocumento: action.idTipoDocumento,
            idLocalEmpresa: action.idLocalEmpresa,
            habilitado: action.habilitado,
            cargo: action.idCargo,
            usuario: action.usuario
          };
        } else {
          return empleadoEdit;
        }

      });
    case fromEmpleado.BORRAR_EMPLEADO:
      return state.filter(empleadoEdit => empleadoEdit.idEmpleado !== action.idEmpleado);
    default:
      return state;
  }
}
