import { Component, OnInit } from '@angular/core';
import { AuthService } from './_servicios/auth.service';
import { Autenticacion } from './_modelos/autenticacion.model';
import { ScriptService } from './_servicios/script.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  autenticacion: Autenticacion;
  currentRoute: string;
  constructor(private authService: AuthService, private scriptService: ScriptService, private router: Router) {
    this.authService.autenticacion.subscribe(
      x => (this.autenticacion = x)
    );
  }

  ngOnInit() {
  }

}


