export class DocumentoVentaDetalle {
  idDocumentoVentaDetalle: number;     // 1
  idDocumentoVenta: number;            // 1
  unidadMedida: string;                // NIU
  productoId: number;                  // 1
  productoNombre: string;              // Turron DP 1KG
  productoDescripcion: string;
  productoCodigo: string;              // 1000001
  productoCodigoInterno: string;       // 1000001
  productoValorUnitario: number;       // 10.50 Monto del Producto sin IGV
  productoPrecioUnitario: number;      // 10.50 Monto del Producto con IGV
  descuento: number;                   // 1.80
  descuentoPorcentaje: number;         // 10
  descuentoHabilitado: boolean;        // true
  descuentoCodigo: string;             // 2548
  descuentoValidado: boolean;          // true
  cantidad: number;                    // 10
  subtotal: number;                    // 100
  igv: number;                         // 100
  total: number;                       // 100
}
