import { Component, OnInit, ViewChildren } from '@angular/core';
import { RequestService } from 'src/app/_servicios/request.service';
import { AuthService } from 'src/app/_servicios/auth.service';
import { Autenticacion } from 'src/app/_modelos/autenticacion.model';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-venta-home',
  templateUrl: './venta-home.component.html',
  styleUrls: ['./venta-home.component.css']
})
export class VentaHomeComponent implements OnInit {
  autenticacion: Autenticacion;
  autenticacionSubscription: Subscription;
  tipoVenta: string;
  selectedValue: string = '0';
  mostrar = false;

  constructor(
    private request: RequestService,
    private authService: AuthService
  ) {
    this.autenticacionSubscription = this.authService.autenticacion.subscribe(user => {
      this.autenticacion = user;
      console.log(this.autenticacion);
    });
  }

  ngOnInit() {
    this.request.postValidarLocal(this.autenticacion.idEmpleado).subscribe(
      res => {
        console.log(res);
        if (res) {
          this.mostrar = (res.idLocal === 2 || res.idLocal === 3) ? true : false;
        }
      }
    );
  }



}
