import { RequestService } from 'src/app/_servicios/request.service';
import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, OnChanges } from '@angular/core';
import { Local } from '../../../_modelos/local.model';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as fromLocal from './../local.actions';
import { Observable } from 'rxjs';
import { EditarLocalAction } from '../local.actions';

declare var $: any;

@Component({
  selector: 'app-mant-local-actualizar',
  templateUrl: './mant-local-actualizar.component.html',
  styleUrls: ['./mant-local-actualizar.component.css']
})
export class MantLocalActualizarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  @Input() idLocal: Observable<any>;
  locales: Local[] = [];
  operando: boolean;
  public local: Local = null;

  formularioActualizar: FormGroup;


  constructor(
    private store: Store<AppState>,
    private request: RequestService,
    private formBuilder: FormBuilder
  ) { }

  seleccionar(idLocal: number) {
    this.local = JSON.parse(JSON.stringify(this.locales)).find(i => i.idLocal === idLocal);
    console.log(this.local);
    if (this.local) {
      this.setFormulario(this.local);
    }
  }

  ngOnInit() {
    this.operando = false;
    this.buildForm();

    this.store.subscribe(
      state => {
        this.locales = state.locales;
        console.log(this.locales);
      }
    );
  }

  buildForm() {
    this.formularioActualizar = this.formBuilder.group({
      nombre: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      descripcion: [null, [Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      direccion: [null],
      departamento: [null],
      provincia: [null],
      distrito: [null],
      aforo: [null, [Validators.pattern('^[0-9]*$')]]
    });
  }

  validarFormulario() {
    if (this.formularioActualizar.controls.nombre.invalid) { this.formularioActualizar.controls.nombre.markAsDirty(); }
    if (this.formularioActualizar.controls.descripcion.invalid) { this.formularioActualizar.controls.descripcion.markAsDirty(); }
  }

  private setFormulario(local: Local) {
    this.formularioActualizar.controls.nombre.setValue(local.nombre);
    this.formularioActualizar.controls.descripcion.setValue(local.descripcion);
    this.formularioActualizar.controls.direccion.setValue(local.direccion);
    this.formularioActualizar.controls.departamento.setValue(local.departamento);
    this.formularioActualizar.controls.provincia.setValue(local.provincia);
    this.formularioActualizar.controls.distrito.setValue(local.distrito);
    this.formularioActualizar.controls.aforo.setValue(local.aforo);
  }

  public actualizarLocal(){

    if (this.formularioActualizar.invalid) {
      alert('Formulario con errores');
      this.validarFormulario();
      return;
    }
    this.operando = true;

    const local = new Local();
    local.idLocal = this.local.idLocal;
    local.nombre = this.formularioActualizar.controls.nombre.value;
    local.descripcion = this.formularioActualizar.controls.descripcion.value;
    local.direccion = this.formularioActualizar.controls.direccion.value;
    local.distrito = this.formularioActualizar.controls.distrito.value;
    local.provincia = this.formularioActualizar.controls.provincia.value;
    local.departamento = this.formularioActualizar.controls.departamento.value;
    local.aforo = this.formularioActualizar.controls.aforo.value;

    this.request.putActualizarLocal(local).subscribe(
      data => {
        console.log(data);
        if (data.status) {
          this.evento.next('rerender');
          $('#modalMantLocalActualizar').modal('hide');
          const action = new fromLocal.EditarLocalAction(
            local.idLocal,
            local.nombre,
            local.descripcion,
            local.direccion,
            local.distrito,
            local.provincia,
            local.departamento,
            local.aforo
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
