export class Producto {
  idProducto: number;
  idCategoria: number;
  codigo: string;
  codigoInterno: string;
  nombre: string;
  marca: string;
  descripcion: string;
  stockFisico: number;
  stockLogico: number;
  precioVenta: number;
  imagen: string;
  habilitado: number;

  constructor(
    idProducto?: number,
    idCategoria?: number,
    codigo?: string,
    codigoInterno?: string,
    nombre?: string,
    marca?: string,
    descripcion?: string,
    stockFisico?: number,
    stockLogico?: number,
    precioVenta?: number,
    imagen?: string,
    habilitado?: number
  ) {
    this.idProducto = idProducto;
    this.idCategoria = idCategoria;
    this.codigo = codigo;
    this.codigoInterno = codigoInterno;
    this.nombre = nombre;
    this.marca = marca;
    this.descripcion = descripcion;
    this.stockFisico = stockFisico;
    this.stockLogico = stockLogico;
    this.precioVenta = precioVenta;
    this.imagen = imagen;
    this.habilitado = habilitado;
  }
}
