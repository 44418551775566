export class Cliente {
  idCliente: number;
  tipoDocumento: number;
  numeroDocumento: string;
  denominacion: string;
  direccion: string;
  email: string;
  celular: string;
  telefono: string;
  habilitado: number;

  constructor(
    idCliente?: number,
    tipoDocumento?: number,
    numeroDocumento?: string,
    denominacion?: string,
    direccion?: string,
    email?: string,
    celular?: string,
    telefono?: string,
    habilitado?: number
  ) {
    this.idCliente = idCliente;
    this.tipoDocumento = tipoDocumento;
    this.numeroDocumento = numeroDocumento;
    this.denominacion = denominacion;
    this.direccion = direccion;
    this.email = email;
    this.celular = celular;
    this.telefono = telefono;
    this.habilitado = habilitado;
  }
}
