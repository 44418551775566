import { Component, OnInit, QueryList, ViewChildren, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { RequestService } from '../../../_servicios/request.service';
import { Local } from '../../../_modelos/local.model';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { MantLocalActualizarComponent } from '../mant-local-actualizar/mant-local-actualizar.component';
import { MantLocalEliminarComponent } from '../mant-local-eliminar/mant-local-eliminar.component';
import * as fromLocal from './../local.actions';

declare var $: any;

@Component({
  selector: 'app-mant-local-home',
  templateUrl: './mant-local-home.component.html',
  styleUrls: ['./mant-local-home.component.css']
})
export class MantLocalHomeComponent implements OnDestroy, OnInit {

  locales: Local[] = [];
  localSelected: any;

  dtOptions: any = {};
  dtTrigger: Subject<null> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @ViewChild(MantLocalActualizarComponent, {static: false}) actualizarModal: MantLocalActualizarComponent ;
  @ViewChild(MantLocalEliminarComponent, {static: false}) eliminarModal: MantLocalEliminarComponent ;

  constructor(
    private requestService: RequestService,
    private store: Store<AppState>
  ) {
    this.requestService.getListarLocales().subscribe(
      locales => {
        const action = new fromLocal.InicializarLocalAction(locales);
        this.dtTrigger.next();
        $('.gj-overlay-loading').hide();
        this.store.dispatch(action);
      }
    );
  }

  ngOnInit() {
    this.dtOptions = {
      initComplete: () => {
        $('div.dataTables_filter input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'copy', text: 'Copiar', title: 'Grupo Jaramillo - Mantenimiento - Locales' },
        { extend: 'print', text: 'Imprimir', title: 'Grupo Jaramillo - Mantenimiento - Locales' },
        { extend: 'excel', text: 'Excel', title: 'Grupo Jaramillo - Mantenimiento - Locales' }
      ],
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_ registros',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: 'Siguiente',
          sPrevious: 'Anterior'
        },
        oAria: {
          sSortAscending: ': Activar para ordenar la columna de manera ascendente',
          sSortDescending: ': Activar para ordenar la columna de manera descendente'
        }
      }
    };

    this.localSelected = 0;
    this.store.subscribe(
      state => {
        this.locales = state.locales;
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public insertarLocal() {
    $('#modalMantLocalInsertar').modal('show');
  }

  public editarLocal(idLocal: number) {
    this.actualizarModal.seleccionar(idLocal);
    $('#modalMantLocalActualizar').modal('show');
  }

  public eliminarLocal(idLocal: number) {
    this.eliminarModal.seleccionar(idLocal);
    $('#modalMantLocalEliminar').modal('show');
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

}
