import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { RequestService } from '../../../_servicios/request.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Categoria } from '../../../_modelos/categoria.model';
import * as fromCategoria from './../categoria.actions';

declare var $: any;

@Component({
  selector: 'app-mant-categoria-insertar',
  templateUrl: './mant-categoria-insertar.component.html',
  styleUrls: ['./mant-categoria-insertar.component.css']
})
export class MantCategoriaInsertarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  formularioInsertar: FormGroup;
  operando: boolean;

  constructor(
    private requestService: RequestService,
    private store: Store<AppState>,
    private formBuilder: FormBuilder
    ) { }

  ngOnInit() {
    this.operando = false;
    this.buildForm();
  }

  buildForm() {
    this.formularioInsertar = this.formBuilder.group({
      nombre: [null, [Validators.required]],
      descripcion: [null]
    });
  }

  exit() {
    this.formularioInsertar.reset();

    $('#modalMantCategoriaInsertar').modal('hide');
  }

  validarFormulario() {
    if (this.formularioInsertar.controls.nombre.invalid) { this.formularioInsertar.controls.nombre.markAsDirty(); }
    if (this.formularioInsertar.controls.descripcion.invalid) { this.formularioInsertar.controls.descripcion.markAsDirty(); }
  }


  agregarCategoria() {

    if (this.formularioInsertar.invalid) {
      alert('Formulario con errores');
      this.validarFormulario();
      return;
    }

    this.operando = true;

    const categoria = new Categoria();
    categoria.nombre = this.formularioInsertar.controls.nombre.value;
    categoria.descripcion = this.formularioInsertar.controls.descripcion.value;
    this.requestService.postAgregarCategoria(categoria).subscribe(
      data => {
        console.log(data);

        if(data.status) {
          this.evento.next('rerender');
          $('#modalMantCategoriaInsertar').modal('hide');
          const action = new fromCategoria.AgregarCategoriaAction(
            data.data,
            categoria.nombre,
            categoria.descripcion,
            1
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
