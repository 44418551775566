import { Component, OnInit, QueryList, ViewChildren, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { RequestService } from '../../../_servicios/request.service';
import { Categoria } from '../../../_modelos/categoria.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducers';
import * as fromCategoria from './../categoria.actions';
import { MantCategoriaInsertarComponent } from './../mant-categoria-insertar/mant-categoria-insertar.component';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { MantCategoriaActualizarComponent } from '../mant-categoria-actualizar/mant-categoria-actualizar.component';
import { MantCategoriaEliminarComponent } from '../mant-categoria-eliminar/mant-categoria-eliminar.component';

declare var $: any;

@Component({
  selector: 'app-mant-categoria-home',
  templateUrl: './mant-categoria-home.component.html',
  styleUrls: ['./mant-categoria-home.component.css']
})
export class MantCategoriaHomeComponent implements OnDestroy, OnInit {

  categorias: Categoria[] = [];
  categoriaSelected: any;

  dtOptions: any = {};
  dtTrigger: Subject<null> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @ViewChild(MantCategoriaActualizarComponent, {static: false}) actualizarModal: MantCategoriaActualizarComponent ;
  @ViewChild(MantCategoriaEliminarComponent, {static: false}) eliminarModal: MantCategoriaEliminarComponent ;

  constructor(
    private requestService: RequestService,
    private store: Store<AppState>
  ) {
    this.requestService.getListarCategorias().subscribe(
      categorias => {
        const action = new fromCategoria.InicializarCategoriaAction(categorias);
        this.dtTrigger.next();
        $('.gj-overlay-loading').hide();
        this.store.dispatch(action);
      }
    );
  }

  ngOnInit() {
    this.dtOptions = {
      initComplete: () => {
        $('div.dataTables_filter input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'copy', text: 'Copiar', title: 'Grupo Jaramillo - Mantenimiento - Categorías' },
        { extend: 'print', text: 'Imprimir', title: 'Grupo Jaramillo - Mantenimiento - Categorías' },
        { extend: 'excel', text: 'Excel', title: 'Grupo Jaramillo - Mantenimiento - Categorías' }
      ],
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_ registros',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: 'Siguiente',
          sPrevious: 'Anterior'
        },
        oAria: {
          sSortAscending: ': Activar para ordenar la columna de manera ascendente',
          sSortDescending: ': Activar para ordenar la columna de manera descendente'
        }
      }
    };

    this.categoriaSelected = 0;
    this.store.subscribe(
      state => {
        this.categorias = state.categorias;
      }
    );
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public insertarCategoria() {
    $('#modalMantCategoriaInsertar').modal('show');
  }

  public editarCategoria(idCategoria: number) {
    this.actualizarModal.seleccionar(idCategoria);
    $('#modalMantCategoriaActualizar').modal('show');
  }

  public eliminarCategoria(idCategoria: number) {
    this.eliminarModal.seleccionar(idCategoria);
    $('#modalMantCategoriaEliminar').modal('show');
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
}
