import { Action } from '@ngrx/store';
import { Producto } from 'src/app/_modelos/producto.model';

export const INICIALIZAR_TODO_PRODUCTO = '[PRODUCTO] Inicializar Todo Producto';
export const AGREGAR_PRODUCTO = '[PRODUCTO] Agregar Producto';
export const EDITAR_PRODUCTO = '[PRODUCTO] Editar Producto';
export const BORRAR_PRODUCTO = '[PRODUCTO] Borrar Producto';
export const BORRAR_TODO_PRODUCTO = '[PRODUCTO] Borrar Todo Producto';

export class InicializarProductoAction implements Action {
  readonly type = INICIALIZAR_TODO_PRODUCTO;

  constructor(
    public productos: Producto[]
  ) {}
}

export class AgregarProductoAction implements Action {
  readonly type = AGREGAR_PRODUCTO;

  constructor(
    public idProducto: number,
    public idCategoria: number,
    public codigo: string,
    public codigoInterno: string,
    public nombre: string,
    public marca: string,
    public descripcion: string,
    public stockFisico: number,
    public stockLogico: number,
    public precioVenta: number,
    public imagen: string,
    public habilitado: number
  ) {}
}

export class EditarProductoAction implements Action {
  readonly type = EDITAR_PRODUCTO;

  constructor(
    public idProducto: number,
    public idCategoria: number,
    public codigo: string,
    public codigoInterno: string,
    public nombre: string,
    public marca: string,
    public descripcion: string,
    public stockFisico: number,
    public stockLogico: number,
    public precioVenta: number,
    public imagen: string,
    public habilitado: number
  ) { }
}

export class BorrarProductoAction implements Action {
  readonly type = BORRAR_PRODUCTO;

  constructor(
    public idProducto: number
  ) {}
}

export type Acciones = AgregarProductoAction |
                        EditarProductoAction |
                        BorrarProductoAction |
                        InicializarProductoAction;
