import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-reportes-home',
  templateUrl: './reportes-home.component.html',
  styleUrls: ['./reportes-home.component.css']
})
export class ReportesHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#zero-configuration').css({'background':'red'});
  }

}
