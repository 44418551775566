export class AppSettings {
  // public static API_ENDPOINT = 'https://grupojaramillo.jefcode.com/restci/api/';
  // public static API_ENDPOINT = 'http://localhost/zeusapi/api/';
  public static WEB_API = 'http://intranet.donapepa.pe/zeusapi/';
  public static API_ENDPOINT = 'http://intranet.donapepa.pe/zeusapi/index.php/api/';
  // public static WEB_API = 'http://localhost/zeusapi/';
  // public static API_ENDPOINT = 'http://localhost/zeusapi/index.php/api/';
  public static WS_ENDPOINT_RUC = 'https://dniruc.apisperu.com/api/v1/ruc/';
  public static WS_ENDPOINT_DNI = 'https://dniruc.apisperu.com/api/v1/dni/';
  public static WS_TOKEN_CONSULTA = '?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImVjZXJuYXJ1aXpAZ21haWwuY29tIn0.wwydYdCElOkckjY5f2DlgfXjlN1qBl4dEl78pC2xSJ0';
}
