import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-acciones',
  templateUrl: './menu-acciones.component.html',
  styleUrls: ['./menu-acciones.component.css']
})
export class MenuAccionesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
