import { Action } from '@ngrx/store';
import { Empleado } from '../../_modelos/empleado.model';

export const INICIALIZAR_TODO_EMPLEADO = '[EMPLEADO] Inicializar Todo Empleado';
export const AGREGAR_EMPLEADO = '[EMPLEADO] Agregar Empleado';
export const EDITAR_EMPLEADO = '[EMPLEADO] Editar Empleado';
export const BORRAR_EMPLEADO = '[EMPLEADO] Borrar Empleado';
export const BORRAR_TODO_EMPLEADO = '[EMPLEADO] Borrar Todo Empleado';

export class InicializarEmpleadoAction implements Action {
  readonly type = INICIALIZAR_TODO_EMPLEADO;

  constructor(
    public empleados: Empleado[]
  ) {}
}

export class AgregarEmpleadoAction implements Action {
  readonly type = AGREGAR_EMPLEADO;

  constructor(
    public idEmpleado: number,
    public nombres: string,
    public apellidoPaterno: string,
    public apellidoMaterno: string,
    public fechaNacimiento: string,
    public foto: string,
    public direccion: string,
    public telefono: string,
    public celular: string,
    public numberoDocumento: number,
    public idTipoDocumento: number,
    public idLocalEmpresa: number,
    public habilitado: number,
    public idCargo: string,
    public usuario: string
  ) {}
}

export class EditarEmpleadoAction implements Action {
  readonly type = EDITAR_EMPLEADO;

  constructor(
    public idEmpleado: number,
    public nombres: string,
    public apellidoPaterno: string,
    public apellidoMaterno: string,
    public fechaNacimiento: string,
    public foto: string,
    public direccion: string,
    public telefono: string,
    public celular: string,
    public numberoDocumento: number,
    public idTipoDocumento: number,
    public idLocalEmpresa: number,
    public habilitado: number,
    public idCargo: string,
    public usuario: string
  ) {}
}

export class BorrarEmpleadoAction implements Action {
  readonly type = BORRAR_EMPLEADO;

  constructor(
    public idEmpleado: number
  ) {}
}

export type Acciones =  AgregarEmpleadoAction |
                        EditarEmpleadoAction |
                        BorrarEmpleadoAction |
                        InicializarEmpleadoAction;
