import { Cliente } from './../../_modelos/cliente.model';
import { Action } from '@ngrx/store';

export const INICIALIZAR_TODO_CLIENTE = '[CLIENTE] Inicializar Todo Cliente';
export const AGREGAR_CLIENTE = '[CLIENTE] Agregar Cliente';
export const EDITAR_CLIENTE = '[CLIENTE] Editar Cliente';
export const BORRAR_CLIENTE = '[CLIENTE] Borrar Cliente';
export const BORRAR_TODO_CLIENTE = '[CLIENTE] Borrar Todo Cliente';

export class InicializarClienteAction implements Action {
  readonly type = INICIALIZAR_TODO_CLIENTE;

  constructor(
    public clientes: Cliente[]
  ) {}
}

export class AgregarClienteAction implements Action {
  readonly type = AGREGAR_CLIENTE;

  constructor(
    public idCliente: number,
    public tipoDocumento: number,
    public numeroDocumento: string,
    public denominacion: string,
    public direccion: string,
    public email: string,
    public celular: string,
    public telefono: string,
    public habilitado: number
  ) {}
}

export class EditarClienteAction implements Action {
  readonly type = EDITAR_CLIENTE;

  constructor(
    public idCliente: number,
    public tipoDocumento: number,
    public numeroDocumento: string,
    public denominacion: string,
    public direccion: string,
    public email: string,
    public celular: string,
    public telefono: string,
    public habilitado: number
  ) { }
}

export class BorrarClienteAction implements Action {
  readonly type = BORRAR_CLIENTE;

  constructor(
    public idCliente: number
  ) {}
}

export type Acciones = AgregarClienteAction | EditarClienteAction
  | BorrarClienteAction  | InicializarClienteAction;
