import { WSConsultaDNIAPI } from './../_modelos/wsConsultaDNIAPI.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from '../app.settings';
import { Local } from '../_modelos/local.model';
import { Producto } from '../_modelos/producto.model';
import { Empleado } from '../_modelos/empleado.model';
import { Categoria } from '../_modelos/categoria.model';
import { vPrepararVenta } from '../_modelos/vPrepararVenta.model';
import { Cliente } from '../_modelos/cliente.model';
import { WSConsultaDNI } from '../_modelos/wsConsultaDNI.model';
import { WSConsultaRUC } from '../_modelos/wsConsultaRUC.model';
import { SolAutorizacionDescuento } from '../_modelos/solAutorizacionDescuento.model';
import { Asignacion } from '../_modelos/asignacion.model';
import { PrepararAsignacion } from '../_modelos/prepararAsignacion.model';
import { DatosInicialesVenta } from '../_modelos/datosinicialesventa.model';
import { DocumentoVenta } from '../_modelos/documentoventa.model';
import { ResponseAPI } from '../_modelos/response.model';
import { Usuario } from '../_modelos/usuario.model';
import { ReporteVentas } from '../_modelos/reporteventas.model';
import { Liquidaciondiaria } from '../_modelos/liquidar.model';
import { WSConsultaRUCAPI } from '../_modelos/wsConsultaRUCAPI.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

const httpOptionsAPIDNI = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImM3MTYwMjAyZWM5MGNlNDhkOWY1N2UwOWM1N2RmMzc5YTQ5ODhkNGU4MmM1NGRlYjIyNjdiZmZmMGI4YzU2MDU3MDY4MWNlNDk1OWZiNWNhIn0.eyJhdWQiOiIxIiwianRpIjoiYzcxNjAyMDJlYzkwY2U0OGQ5ZjU3ZTA5YzU3ZGYzNzlhNDk4OGQ0ZTgyYzU0ZGViMjI2N2JmZmYwYjhjNTYwNTcwNjgxY2U0OTU5ZmI1Y2EiLCJpYXQiOjE1NzEzMjg4OTUsIm5iZiI6MTU3MTMyODg5NSwiZXhwIjoxODg2OTQ4MDk1LCJzdWIiOiIyNjYiLCJzY29wZXMiOlsiKiJdfQ.V3onjA_u9vw4jdD5Mb0N7Aq_34zBbQUBwffg3DN-LDbMTWesLIApGucyN6-_BokMZdehNrrMiZDnOyI7LBmK0ewMh_Rm-xmTjY24v-pOVAcZ8JVb11XW3NNfmdV8daOe7oWBVY_p9OuomjmXkUWVnpWqqOvmE0ojwe9hmI1oBH8taVP0g5WsmFXH8OgXNHOh0DQnDKQRmr-p8LWhvq4oBO4_jsKhbjj-8wKvZ0BAJvo-dkKioXotU7IzUrZberNfnkCNqnskXkh8rcpFIGDJij6b-GZFwqBuwGWvoYkjs-99dNrV0-zTNr7CqKlk1z5mvruMF5PjPB5CZC-Zy9FJH452CeHyelPDmp8I4B6d01OOQw0rcncxln1Q_OStsFhvmFvI5I-RMCC1U6o8IgLarMLYu50DwMwA5FDOOJdEXvvPQJZUZz48fwVeq5VUN3Z9550gU6P4cYm2g-2J4VU1X8SVJKbUK6YYXxg7j6_uE1BEfE2LB26Egroohr3IsMSHgWh0WCIKUrv4sxkT56FNvAnronOxsnywtSes2OAXMe_Sio9l8e4GaF7L6_LNo__WV2aOwGfvGJNahjMpoqS9vtz7MWUcIGhERRAiP7oNpwjRoaYkMdwnXttgZ-YzanRCZrOFvbIzm4I2k-cc-92sSZ-K0uCnVOtNUDK0GZNY9WI'
  })
}

@Injectable({ providedIn: 'root' })
export class RequestService {

  constructor(private http: HttpClient) {
  }

  // Auditoria
  getListarEmpleadosAuditoria() {
    return this.http.get<any[]>(AppSettings.API_ENDPOINT + 'auditoria/empleados', httpOptions);
  }

  getListarVentasAuditoria(idEmpleado: number, fecha: string) {
    return this.http.get<any[]>(AppSettings.API_ENDPOINT + 'auditoria/ventas/' + idEmpleado + '/' + fecha, httpOptions);
  }

  // Reportes

  public getReporteVentas(idUsuario: number, ini: string, fin: string) {
    return this.http.get<ReporteVentas[]>(AppSettings.API_ENDPOINT + 'reporte/ventas/' + idUsuario + '/' + ini + '/' + fin, httpOptions);
  }

  public getReporteLiquidacionMaestro(idUsuario: number) {
    return this.http.get<ReporteVentas[]>(AppSettings.API_ENDPOINT + 'reporte/liquidacionmaestro/' + idUsuario, httpOptions);
  }

  // Venta

  postGuardarComprobante(documentoVenta: DocumentoVenta) {
    return this.http.post(AppSettings.API_ENDPOINT + 'venta/guardar', documentoVenta, httpOptions)
    .pipe(map(res => {
      return res;
    }));
  }

  postEmitirComprobante(documentoVenta: DocumentoVenta) {
    return this.http.post<ResponseAPI>(AppSettings.API_ENDPOINT + 'venta/emitir', documentoVenta, httpOptions)
    .pipe(map(res => {
      return res;
    }));
  }

  putAnularComprobante(documentoVenta: DocumentoVenta) {
    return this.http.put<ResponseAPI>(AppSettings.API_ENDPOINT + 'venta/anular', { documentoVenta }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  // WS

  postGuardarAsignacion(asignacion: Asignacion[]) {
    return this.http.post<any>(AppSettings.API_ENDPOINT + 'asignacion/guardar', { data: asignacion }, httpOptions)
    .pipe(map(res => {
      return res;
    }));
  }

  getPrepararAsignacion() {
    return this.http.get<PrepararAsignacion>(AppSettings.API_ENDPOINT + 'asignacion/datosiniciales', httpOptions).pipe();
  }

  getConsultarDNI(dni: string) {
    return this.http.get<WSConsultaDNI>(AppSettings.WS_ENDPOINT_DNI + dni + AppSettings.WS_TOKEN_CONSULTA, httpOptions).pipe();
  }

  postConsultarDNI(dniConsultar: string) {
    return this.http.post<WSConsultaDNIAPI>('https://api.apirest.pe/api/getDni', {dni: dniConsultar}, httpOptionsAPIDNI).pipe();
  }

  postConsultarRUC(rucConsultar: string) {
    return this.http.post<WSConsultaRUCAPI>('https://api.apirest.pe/api/getRuc', {ruc: rucConsultar}, httpOptionsAPIDNI).pipe();
  }

  getConsultarRUC(ruc: string) {
    return this.http.get<WSConsultaRUC>(AppSettings.WS_ENDPOINT_RUC + ruc + AppSettings.WS_TOKEN_CONSULTA, httpOptions).pipe();
  }

  // LISTAR

  getListarLocales() {
    return this.http.get(AppSettings.API_ENDPOINT + 'local/listar', httpOptions).pipe(
      map( this.buildLocales )
    );
  }

  private buildLocales(objLocales: object) {
    const locales: Local[] = [];
    Object.keys( objLocales ).forEach ( key => {
      const local: Local = objLocales[key];
      locales.push(local);
    });
    return locales;
  }

  getListarEmpleados() {
    return this.http.get(AppSettings.API_ENDPOINT + 'empleado/listar', httpOptions).pipe(
      map( this.buildEmpleados )
    );
  }

  private buildEmpleados(objEmpleados: object) {
    const empleados: Empleado[] = [];
    Object.keys( objEmpleados ).forEach ( key => {
      const empleado: Empleado = objEmpleados[key];
      empleados.push(empleado);
    });
    return empleados;
  }

  getListarProductos() {
    return this.http.get(AppSettings.API_ENDPOINT + 'producto/listar', httpOptions).pipe(
      map( this.buildProductos )
    );
  }

  private buildProductos(objProductos: object) {
    const productos: Producto[] = [];
    Object.keys( objProductos ).forEach ( key => {
      const producto: Producto = objProductos[key];
      productos.push(producto);
    });
    return productos;
  }

  getListarCategorias() {
    return this.http.get(AppSettings.API_ENDPOINT + 'categoria/listar', httpOptions).pipe(
      map( this.buildCategorias )
    );
  }

  private buildCategorias(objCategorias: object) {
    const categorias: Categoria[] = [];
    Object.keys( objCategorias ).forEach ( key => {
      const categoria: Categoria = objCategorias[key];
      categorias.push(categoria);
    });
    return categorias;
  }

  getListarClientes() {
    return this.http.get<Cliente[]>(AppSettings.API_ENDPOINT + 'cliente/listar', httpOptions);
  }

  public postDatosInicialesBoleta(idUsuario: number) {
    return this.http.post<DatosInicialesVenta>(AppSettings.API_ENDPOINT + 'venta/datosinicialesboleta',
    { usuario: idUsuario}, httpOptions);
  }

  public postDatosInicialesFactura(idUsuario: number) {
    return this.http.post<DatosInicialesVenta>(AppSettings.API_ENDPOINT + 'venta/datosinicialesfactura',
    { usuario: idUsuario}, httpOptions);
  }

  public postDatosInicialesNotaVenta(idUsuario: number) {
    return this.http.post<DatosInicialesVenta>(AppSettings.API_ENDPOINT + 'venta/datosinicialesnotaventa',
    { usuario: idUsuario}, httpOptions);
  }


  public getVentas() {
    return this.http.get<any>(AppSettings.API_ENDPOINT + 'venta/listar', httpOptions);
  }

  public getVentasUsuario(idUsuario: number) {
    return this.http.get<any>(AppSettings.API_ENDPOINT + 'venta/listar/' + idUsuario, httpOptions);
  }

  public getVentasLiquidarUsuario(idUsuario: number) {
    return this.http.get<Liquidaciondiaria[]>(AppSettings.API_ENDPOINT + 'venta/liquidar/' + idUsuario, httpOptions);
  }

  public postVentasLiquidarDiario(liquidaciones: Liquidaciondiaria[]){
    return this.http.post<any>(AppSettings.API_ENDPOINT + 'venta/liquidardiario',
    { data: liquidaciones}, httpOptions);
  }

  // DESCUENTOS

  postSolicitarAutorizacion(solicitud: SolAutorizacionDescuento) {
    return this.http.post<ResponseAPI>(AppSettings.API_ENDPOINT + 'venta/solicitardescuento', { solicitud }, httpOptions)
    .pipe(map(res => {
      return res;
    }));
  }

  postValidarAutorizacion(solicitud: SolAutorizacionDescuento) {
    return this.http.post(AppSettings.API_ENDPOINT + 'venta/validardescuento', { solicitud }, httpOptions)
    .pipe(map(res => {
      return res;
    }));
  }

  // REGISTRAR

  postAgregarLocal(local: Local) {
    return this.http.post<any>(AppSettings.API_ENDPOINT + 'local/agregar', { local }, httpOptions)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }

  postAgregarEmpleado(empleado: Empleado) {
    return this.http.post<any>(AppSettings.API_ENDPOINT + 'empleado/agregar', { empleado }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  postAgregarProducto(producto: Producto) {
    return this.http.post<any>(AppSettings.API_ENDPOINT + 'producto/agregar', { producto }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  postAgregarCategoria(categoria: Categoria) {
    return this.http.post<any>(AppSettings.API_ENDPOINT + 'categoria/agregar', { categoria }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  postAgregarCliente(cliente: Cliente) {
    return this.http.post<any>(AppSettings.API_ENDPOINT + 'cliente/agregar', { cliente }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  // ACTUALIZAR

  putActualizarClaveUsuario(usuario: Usuario) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + 'usuario/actualizarclave', { usuario }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  putActualizarLocal(local: Local) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + 'local/actualizar', { local }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  putActualizarEmpleado(empleado: Empleado) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + 'empleado/actualizar', { empleado }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  putActualizarProducto(producto: Producto) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + 'producto/actualizar', { producto }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  putActualizarCategoria(categoria: Categoria) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + 'categoria/actualizar', { categoria }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  putActualizarCliente(cliente: Cliente) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + 'cliente/actualizar', { cliente }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  // ELIMINAR

  putEliminarLocal(local: Local) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + 'local/eliminar', { local }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  putEliminarEmpleado(empleado: Empleado) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + 'empleado/eliminar', { empleado }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  putEliminarProducto(producto: Producto) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + 'producto/eliminar', { producto }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  putEliminarCategoria(categoria: Categoria) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + 'categoria/eliminar', { categoria }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  putEliminarCliente(cliente: Cliente) {
    return this.http.put<any>(AppSettings.API_ENDPOINT + 'cliente/eliminar', { cliente }, httpOptions)
      .pipe(map(res => {
        return res;
      }));
  }

  public postValidarLocal(idUsuario: number) {
    return this.http.post<any>(AppSettings.API_ENDPOINT + 'venta/validarlocal',
    { usuario: idUsuario}, httpOptions);
  }

  crearPDF(boleta: any) {
    console.log('boleta', boleta);
    const url = AppSettings.API_ENDPOINT + 'archivo/crearpdf';
    //const url = "http://localhost/zeus/api/api/archivo/crearpdf";
    //const formData = new FormData();
    //formData.append('data', base64);
    return this.http.post(url, boleta);
  }
}
