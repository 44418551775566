import { Observable } from 'rxjs';
import { RequestService } from './../../../_servicios/request.service';
import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, OnChanges } from '@angular/core';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import { Cliente } from '../../../_modelos/cliente.model';
import * as fromCliente from './../cliente.actions';

declare var $: any;

@Component({
  selector: 'app-mant-cliente-eliminar',
  templateUrl: './mant-cliente-eliminar.component.html',
  styleUrls: ['./mant-cliente-eliminar.component.css']
})
export class MantClienteEliminarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  @Input() idCliente: Observable<any>;
  clientes: Cliente[] = [];
  operando: boolean;
  public cliente: Cliente = null;

  constructor(
    private store: Store<AppState>,
    private request: RequestService
  ) { }

  seleccionar(idCliente: number) {
    this.cliente = JSON.parse(JSON.stringify(this.clientes)).find(i => i.idCliente === idCliente);
    console.log(this.cliente);
  }

  ngOnInit() {
    this.operando = false;
    this.store.subscribe(
      state => {
        this.clientes = state.clientes;
        console.log(this.clientes);
      }
    );
  }

  eliminarCliente(idCliente: number) {
    this.operando = true;
    const cliente = new Cliente(idCliente);
    this.request.putEliminarCliente(cliente).subscribe(

      data => {
        console.log(data);
        if (data.status) {
          this.evento.next('rerender');
          $('#modalMantClienteEliminar').modal('hide');
          const action = new fromCliente.BorrarClienteAction(
            idCliente
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
