import { RequestService } from './../../../_servicios/request.service';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Producto } from '../../../_modelos/producto.model';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromProducto from './../producto.actions';

declare var $: any;

@Component({
  selector: 'app-mant-producto-eliminar',
  templateUrl: './mant-producto-eliminar.component.html',
  styleUrls: ['./mant-producto-eliminar.component.css']
})
export class MantProductoEliminarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  @Input() idProducto: Observable<any>;
  productos: Producto[] = [];
  operando: boolean;
  public producto: Producto = null;

  constructor(
    private store: Store<AppState>,
    private request: RequestService
  ) { }

  seleccionar(idProducto: number) {
    this.producto = JSON.parse(JSON.stringify(this.productos)).find(i => i.idProducto === idProducto);
    console.log(this.producto);
  }

  ngOnInit() {
    this.operando = false;
    this.store.subscribe(
      state => {
        this.productos = state.productos;
        console.log(this.productos);
      }
    );
  }

  eliminarProducto(idProducto: number) {
    this.operando = true;
    const producto = new Producto(idProducto);
    this.request.putEliminarProducto(producto).subscribe(
      data => {
        console.log(data);
        if (data.status) {
          this.evento.next('rerender');
          $('#modalMantProductoEliminar').modal('hide');
          const action = new fromProducto.BorrarProductoAction(
            producto.idProducto
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
