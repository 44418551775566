import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Autenticacion } from '../_modelos/autenticacion.model';
import { AppSettings } from '../app.settings';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({ providedIn: 'root' })
export class AuthService {

  private autenticacionSubject: BehaviorSubject<Autenticacion>;
  public autenticacion: Observable<Autenticacion>;

  constructor(private http: HttpClient) {
    this.autenticacionSubject = new BehaviorSubject<Autenticacion>(JSON.parse(localStorage.getItem('autenticacion')));
    this.autenticacion = this.autenticacionSubject.asObservable();
  }

  public get autenticacionValue(): Autenticacion {
    return this.autenticacionSubject.value;
  }

  login(usuario: string, clave: string) {

    return this.http.post<any>(AppSettings.API_ENDPOINT + 'usuario/autenticar',
      { usuario, clave },
      httpOptions)
      .pipe(map(respuesta => {

        if (respuesta[0]) {
          localStorage.setItem('autenticacion', JSON.stringify(respuesta[0]));
          this.autenticacionSubject.next(respuesta[0]);
        }

        return respuesta;
      }));
  }
  olvidoClave(correo: string) {
    return this.http.post(AppSettings.API_ENDPOINT + 'usuario/solicitarcambioclave', {
      correo
    });
  }
  validarToken(token: string) {
    return this.http.get(AppSettings.API_ENDPOINT + 'usuario/validartoken/' + token);
  }
  cambiarClaveConToken(clave1: string, clave2: string, token: string) {
    return this.http.post(AppSettings.API_ENDPOINT + 'usuario/cambiarclaveportoken', {
      clave1, clave2, token
    });
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('autenticacion');
    this.autenticacionSubject.next(null);
  }
}
