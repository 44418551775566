import { Action } from '@ngrx/store';
import { VCabecera } from '../../../_modelos/vcabecera.model';
import { VDetalle } from '../../../_modelos/vdetalle.model';

export const AGREGAR_VENTA = '[VENTA] Agregar Venta';

export class AgregarVentaAction implements Action {
  readonly type = AGREGAR_VENTA;

  constructor(
    public idVenta: number,
    public cabecera: VCabecera,
    public detalle: VDetalle[],
    public observacion: string
  ) { }
}

export type Acciones = AgregarVentaAction;
