import { Action } from '@ngrx/store';
import { Producto } from '../../../_modelos/producto.model';

export const AGREGAR_VENTA_DETALLE = '[VENTA_DETALLE] Agregar Venta Detalle';
export const BORRAR_VENTA_DETALLE = '[BORRAR_DETALLE] Borrar Venta Detalle';

export class AgregarVentaDetalleAction implements Action {
  readonly type = AGREGAR_VENTA_DETALLE;

  constructor(
    public idDetalle: number,
    public producto: Producto,
    public cantidad: number,
    public unidad: number,
    public valorUnitario: number,
    public precioUnitario: number,
    public importeTotal: number
  ) { }
}

export class BorrarVentaDetalleAction implements Action {
  readonly type = BORRAR_VENTA_DETALLE;

  constructor(
    public idDetalle: number
  ) { }
}

export type Acciones = AgregarVentaDetalleAction | BorrarVentaDetalleAction;
