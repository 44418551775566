import * as fromProducto from './producto.actions';
import { Producto } from '../../_modelos/producto.model';

const estadoInicial: Producto[] = [];

export function productoReducer(
  state = estadoInicial,
  action: fromProducto.Acciones): Producto[] {
  switch (action.type) {
    case fromProducto.INICIALIZAR_TODO_PRODUCTO:
      let productos = [...state];
      productos = action.productos;
      return productos;
    case fromProducto.AGREGAR_PRODUCTO:
      const producto = new Producto(
        action.idProducto,
        action.idCategoria,
        action.codigo,
        action.codigoInterno,
        action.nombre,
        action.marca,
        action.descripcion,
        action.stockFisico,
        action.stockLogico,
        action.precioVenta,
        action.imagen,
        action.habilitado
      );
      return [...state, producto];
    case fromProducto.EDITAR_PRODUCTO:
      return state.map(productoEdit => {

        if (productoEdit.idProducto === action.idProducto) {
          return {
            ...productoEdit,
            idProducto: action.idProducto,
            idCategoria: action.idCategoria,
            codigo: action.codigo,
            codigoInterno: action.codigoInterno,
            nombre: action.nombre,
            marca: action.marca,
            descripcion: action.descripcion,
            stockFisico: action.stockFisico,
            stockLogico: action.stockLogico,
            precioVenta: action.precioVenta,
            imagen: action.imagen,
            habilitado: action.habilitado
          };
        } else {
          return productoEdit;
        }
      });
      case fromProducto.BORRAR_PRODUCTO:
          return state.filter(productoEdit => productoEdit.idProducto !== action.idProducto);
    default:
      return state;
  }
}
