import { RequestService } from './../../../_servicios/request.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { Categoria } from '../../../_modelos/categoria.model';
import * as fromCategoria from './../categoria.actions';
import { Observable } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-mant-categoria-actualizar',
  templateUrl: './mant-categoria-actualizar.component.html',
  styleUrls: ['./mant-categoria-actualizar.component.css']
})
export class MantCategoriaActualizarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  @Input() idCategoria: Observable<any>;
  categorias: Categoria[] = [];
  operando: boolean;
  public categoria: Categoria = null;

  formularioActualizar: FormGroup;

  constructor(
    private store: Store<AppState>,
    private request: RequestService,
    private formBuilder: FormBuilder
  ) { }

  seleccionar(idCategoria: number) {
    this.categoria = JSON.parse(JSON.stringify(this.categorias)).find(i => i.idCategoria === idCategoria);
    console.log(this.categoria);
    if (this.categoria) {
      this.setFormulario(this.categoria);
    }
  }

  ngOnInit() {

    this.operando = false;
    this.buildForm();

    this.store.subscribe(
      state => {
        this.categorias = state.categorias;
        console.log(this.categorias);
      }
    );
  }

  buildForm() {
    this.formularioActualizar = this.formBuilder.group({
      nombre: [null, [Validators.required]],
      descripcion: [null]
    });
  }

  private setFormulario(categoria: Categoria) {
    this.formularioActualizar.controls.nombre.setValue(categoria.nombre);
    this.formularioActualizar.controls.descripcion.setValue(categoria.descripcion);
  }

  validarFormulario() {
    if (this.formularioActualizar.controls.nombre.invalid) { this.formularioActualizar.controls.nombre.markAsDirty(); }
    if (this.formularioActualizar.controls.descripcion.invalid) { this.formularioActualizar.controls.descripcion.markAsDirty(); }
  }

  public actualizarCategoria() {

    if (this.formularioActualizar.invalid) {
      alert('Formulario con errores');
      this.validarFormulario();
      return;
    }

    this.operando = true;

    const categoria = new Categoria();
    categoria.idCategoria = this.categoria.idCategoria;
    categoria.nombre = this.formularioActualizar.controls.nombre.value;
    categoria.descripcion = this.formularioActualizar.controls.descripcion.value;

    this.request.putActualizarCategoria(categoria).subscribe(
      data => {
        console.log(data);
        if (data.status) {
          this.evento.next('rerender');
          $('#modalMantCategoriaActualizar').modal('hide');
          const action = new fromCategoria.EditarCategoriaAction(
            categoria.idCategoria,
            categoria.nombre,
            categoria.descripcion
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
