import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { RequestService } from '../../../_servicios/request.service';
import { Empleado } from '../../../_modelos/empleado.model';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducers';
import * as fromEmpleado from './../empleado.actions';

declare var $: any;

@Component({
  selector: 'app-mant-empleado-insertar',
  templateUrl: './mant-empleado-insertar.component.html',
  styleUrls: ['./mant-empleado-insertar.component.css']
})
export class MantEmpleadoInsertarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  formularioInsertar: FormGroup;
  operando: boolean;

  constructor(
    private store: Store<AppState>,
    private requestService: RequestService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.operando = false;
    this.buildForm();
  }

  buildForm() {
    this.formularioInsertar = this.formBuilder.group({
      nombres: [null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      apellidoPaterno: [null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      apellidoMaterno: [null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      fechaNacimiento: [null],
      tipoDocumento: [null, Validators.required],
      numeroDocumento: [null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(13), Validators.minLength(8)]],
      direccion: [null],
      telefono: [null, [Validators.pattern('^[0-9]*$'), Validators.maxLength(7), Validators.minLength(7)]],
      celular: [null, [Validators.pattern('^[0-9]*$'), Validators.maxLength(9), Validators.minLength(9)]],
      cargo: [2, Validators.required]
    });
  }

  exit() {
    this.formularioInsertar.reset();
    this.formularioInsertar.controls.cargo.setValue(2);
    $('#modalMantEmpleadoInsertar').modal('hide');
  }

  validarFormulario() {
    if (this.formularioInsertar.controls.nombres.invalid) { this.formularioInsertar.controls.nombres.markAsDirty(); }
    if (this.formularioInsertar.controls.apellidoPaterno.invalid) { this.formularioInsertar.controls.apellidoPaterno.markAsDirty(); }
    if (this.formularioInsertar.controls.apellidoMaterno.invalid) { this.formularioInsertar.controls.apellidoMaterno.markAsDirty(); }
    if (this.formularioInsertar.controls.fechaNacimiento.invalid) { this.formularioInsertar.controls.fechaNacimiento.markAsDirty(); }
    if (this.formularioInsertar.controls.tipoDocumento.invalid) { this.formularioInsertar.controls.tipoDocumento.markAsDirty(); }
    if (this.formularioInsertar.controls.numeroDocumento.invalid) { this.formularioInsertar.controls.numeroDocumento.markAsDirty(); }
    if (this.formularioInsertar.controls.direccion.invalid) { this.formularioInsertar.controls.direccion.markAsDirty(); }
    if (this.formularioInsertar.controls.telefono.invalid) { this.formularioInsertar.controls.telefono.markAsDirty(); }
    if (this.formularioInsertar.controls.celular.invalid) { this.formularioInsertar.controls.celular.markAsDirty(); }
    if (this.formularioInsertar.controls.cargo.invalid) { this.formularioInsertar.controls.cargo.markAsDirty(); }
  }

  agregarEmpleado() {

    if (this.formularioInsertar.invalid) {
      alert('Formulario con errores');
      this.validarFormulario();
      return;
    }

    this.operando = true;

    const empleado = new Empleado();
    empleado.nombres = this.formularioInsertar.controls.nombres.value;
    empleado.apellidoPaterno = this.formularioInsertar.controls.apellidoPaterno.value;
    empleado.apellidoMaterno = this.formularioInsertar.controls.apellidoMaterno.value;
    empleado.fechaNacimiento = this.formularioInsertar.controls.fechaNacimiento.value;
    empleado.tipoDocumento = this.formularioInsertar.controls.tipoDocumento.value;
    empleado.numeroDocumento = this.formularioInsertar.controls.numeroDocumento.value;
    empleado.direccion = this.formularioInsertar.controls.direccion.value;
    empleado.telefono = this.formularioInsertar.controls.telefono.value;
    empleado.celular = this.formularioInsertar.controls.celular.value;
    empleado.idCargo = this.formularioInsertar.controls.cargo.value;

    this.requestService.postAgregarEmpleado(empleado).subscribe(
      data => {
        console.log(data);

        if(data.status) {
          this.evento.next('rerender');
          $('#modalMantEmpleadoInsertar').modal('hide');
          const action = new fromEmpleado.AgregarEmpleadoAction(
            data.data,
            empleado.nombres,
            empleado.apellidoPaterno,
            empleado.apellidoMaterno,
            empleado.fechaNacimiento,
            '',
            empleado.direccion,
            empleado.telefono,
            empleado.celular,
            empleado.numeroDocumento,
            empleado.tipoDocumento,
            1,
            1,
            empleado.idCargo,
            ''
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
