import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RequestService } from '../../../../_servicios/request.service';
import { Autenticacion } from '../../../../_modelos/autenticacion.model';
import { Subscription, Subject } from 'rxjs';
import { DocumentoVenta } from '../../../../_modelos/documentoventa.model';
import { AuthService } from '../../../../_servicios/auth.service';
import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'app-devolucion-home',
  templateUrl: './devolucion-home.component.html',
  styleUrls: ['./devolucion-home.component.css']
})
export class DevolucionHomeComponent implements OnInit, OnDestroy{

  dtOptions: any = {};
  dtTrigger: Subject<null> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  autenticacion: Autenticacion;
  autenticacionSubscription: Subscription;
  ventas: DocumentoVenta[] = [];
  ventaSelected: any;

  constructor(
    private request: RequestService,
    private authService: AuthService
  ) {
    this.autenticacionSubscription = this.authService.autenticacion.subscribe(user => {
      this.autenticacion = user;
      console.log(this.autenticacion);
    });
  }

  ngOnInit() {
    this.ventaSelected = {};
    this.ventaSelected.detalle = [];
    this.request.getVentasUsuario(this.autenticacion.idUsuario).subscribe(
      res => {
        this.ventas = res;
        this.dtTrigger.next();
      }
    );

    this.dtOptions = {
      initComplete: () => {
        $('div.dataTables_filter input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_ registros',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: 'Siguiente',
          sPrevious: 'Anterior'
        },
        oAria: {
          sSortAscending: ': Activar para ordenar la columna de manera ascendente',
          sSortDescending: ': Activar para ordenar la columna de manera descendente'
        }
      }
    };

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  visualizarVenta(idDocumentoVenta: number) {
    console.log(idDocumentoVenta);
    console.log(this.ventas);
    this.ventaSelected = this.ventas.filter(item => item.idDocumentoVenta === idDocumentoVenta)[0];
    console.log(this.ventaSelected);
    $('#modalVisualizarVenta').modal('show');
  }

  anularVenta(idDocumentoVenta: number) {
    console.log(this.ventas);
    this.ventaSelected = this.ventas.filter(item => item.idDocumentoVenta === idDocumentoVenta)[0];
    $('#modalAnularVenta').modal('show');
    console.log(this.ventaSelected);
  }

  anular() {
    console.log(this.ventaSelected);
    this.request.putAnularComprobante(this.ventaSelected).subscribe(

      res => {
        if (res.status === true) {
          alert('Se anuló comprobante exitosamente');
          $('#modalAnularVenta').modal('hide');
          this.ventas = this.ventas.filter(obj => obj.idDocumentoVenta !== this.ventaSelected.idDocumentoVenta );
          console.log(this.ventas);
          this.rerender();
        } else {
          alert('Ocurrio un problema');
        }
      }
    );
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

}
