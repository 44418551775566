import { Subscription, Subject } from 'rxjs';
import { Autenticacion } from './../../_modelos/autenticacion.model';
import { AuthService } from './../../_servicios/auth.service';
import { RequestService } from 'src/app/_servicios/request.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ReporteVentas } from '../../_modelos/reporteventas.model';
import { formatDate } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'app-reporte-ventas',
  templateUrl: './reporte-ventas.component.html',
  styleUrls: ['./reporte-ventas.component.css']
})
export class ReporteVentasComponent implements OnInit, OnDestroy {

  dtOptions: any = {};
  dtTrigger: Subject<null> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  autenticacion: Autenticacion;
  autenticacionSubscription: Subscription;

  ventas: ReporteVentas[] = [];

  now: Date = new Date();
  public fechaIni: string = formatDate(this.now.setDate(this.now.getDate() - 30), 'yyyy-MM-dd', 'en');
  public fechaFin: string = formatDate(new Date(), 'yyyy-MM-dd', 'en');

  constructor(
    private request: RequestService,
    private authService: AuthService
  ) {
    this.autenticacionSubscription = this.authService.autenticacion.subscribe(user => {
      this.autenticacion = user;
      console.log(this.autenticacion);
    });
  }

  ngOnInit() {
    this.request.getReporteVentas(this.autenticacion.idUsuario, this.fechaIni, this.fechaFin).subscribe(
      res => {
        // console.log(res);
        this.ventas = res;
        this.dtTrigger.next();
        $('.gj-overlay-loading').hide();
      }
    );

    const key = 'dataTable';

    $.fn[key].ext.search.push((settings: any, data: any, dataIndex: any) => {
      const fecha = data[13];

      const d1 = this.fechaIni.split('-');
      const d2 = this.fechaFin.split('-');
      const c = fecha.split('-');

      // console.log(d1, d2, c);

      const from = new Date(+d1[0], +d1[1] - 1, +d1[2]);
      const to = new Date(+d2[0], +d2[1] - 1, +d2[2]);
      const check = new Date(+c[0], +c[1] - 1, +c[2]);

      if (check >= from && check <= to) {
        return true;
      }
      return false;
    });

    this.dtOptions = {
      initComplete: () => {
        $('div.dataTables_filter input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'copy', text: 'Copiar', title: 'Reporte de Ventas - ' + formatDate(new Date(), 'yyyyMMdd', 'en') },
        {
          extend: 'print',
          text: 'Imprimir',
          title: 'Reporte de Ventas - ' + formatDate(new Date(), 'yyyyMMdd', 'en'),
          customize: ( win ) => {
            $(win.document.body)
                .css( 'font-size', '10pt' );
            $(win.document.body).find( 'table' )
                .addClass( 'compact' )
                .css( { 'font-weight': 'bold', 'color': '#000'} );
        }
        },
        { extend: 'excel', text: 'Excel', title: 'Reporte de Ventas - ' + formatDate(new Date(), 'yyyyMMdd', 'en') }
      ],
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_ registros',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: 'Siguiente',
          sPrevious: 'Anterior'
        },
        oAria: {
          sSortAscending: ': Activar para ordenar la columna de manera ascendente',
          sSortDescending: ': Activar para ordenar la columna de manera descendente'
        }
      },
      responsive: true,
      columnDefs: {

      }
    };
  }

  filterById(): void {
    console.log('Entro');
    $('.gj-overlay-loading').show();
    this.request.getReporteVentas(this.autenticacion.idUsuario, this.fechaIni, this.fechaFin).subscribe(
      res => {
        console.log('Filtrando ...');
        this.ventas = res;
        this.reload();
        // this.dtTrigger.next();
        $('.gj-overlay-loading').hide();
      }
    );

    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.draw();
    // });
  }

  public reload(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
