export class SolAutorizacionDescuento {
    idUsuario: number;
    nroDocumento: string;
    porcentajeDescuento: number;
    codigoProducto: string;
    cantidad: number;
    codigo: string;

    constructor(
        idUsuario: number,
        nroDocumento: string,
        porcentajeDescuento: number,
        codigoProducto: string,
        cantidad: number,
        codigo: string
    ) {
        this.idUsuario = idUsuario;
        this.nroDocumento = nroDocumento;
        this.porcentajeDescuento = porcentajeDescuento;
        this.codigoProducto = codigoProducto;
        this.cantidad = cantidad;
        this.codigo = codigo;
    }
  }
