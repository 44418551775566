import { RequestService } from './../../../_servicios/request.service';
import { Component, OnInit, SimpleChanges, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducers';
import { Categoria } from '../../../_modelos/categoria.model';
import * as fromCategoria from './../categoria.actions';
import { Observable } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-mant-categoria-eliminar',
  templateUrl: './mant-categoria-eliminar.component.html',
  styleUrls: ['./mant-categoria-eliminar.component.css']
})
export class MantCategoriaEliminarComponent implements OnInit {

  @Output() evento = new EventEmitter<string>();
  @Input() idCategoria: Observable<any>;
  categorias: Categoria[] = [];
  operando: boolean;
  public categoria: Categoria = null;

  constructor(
    private store: Store<AppState>,
    private request: RequestService
  ) { }

  seleccionar(idCategoria: number) {
    this.categoria = JSON.parse(JSON.stringify(this.categorias)).find(i => i.idCategoria === idCategoria);
    console.log(this.categoria);
  }

  ngOnInit() {
    this.operando = false;
    this.store.subscribe(
      state => {
        this.categorias = state.categorias;
        console.log(this.categorias);
      }
    );
  }

  eliminarCategoria(idCategoria: number) {
    this.operando = true;
    const categoria = new Categoria(idCategoria);
    this.request.putEliminarCategoria(categoria).subscribe(

      data => {
        console.log(data);
        if (data.status) {
          this.evento.next('rerender');
          $('#modalMantCategoriaEliminar').modal('hide');
          const action = new fromCategoria.BorrarCategoriaAction(
            categoria.idCategoria
          );
          this.store.dispatch(action);
          alert(data.message);
        }
        this.operando = false;
      },
      error => {
        alert(error.message);
        this.operando = false;
      }
    );
  }

}
