export class Categoria {
  idCategoria: number;
  nombre: string;
  descripcion: string;
  habilitado: number;

  constructor( idCategoria?: number, nombre?: string, descripcion?: string, habilitado?: number ) {
    this.idCategoria = idCategoria;
    this.nombre = nombre;
    this.descripcion = descripcion;
    this.habilitado = habilitado;
  }

}
