import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../_servicios/auth.service';
import * as printJS from 'print-js';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formularioLogin: FormGroup;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    if (this.authService.autenticacionValue) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit() {

    this.formularioLogin = this.formBuilder.group(
      {
        usuario: ['', Validators.required],
        clave: ['', Validators.required]
      }
    )

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  get f() { return this.formularioLogin.controls; }

  onSubmit() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.formularioLogin.invalid) {
      alert('Formulario Inválido');
      return;
    }
    this.authService.login(this.f.usuario.value, this.f.clave.value)
      .pipe(first())
      .subscribe(
        data => {
          // this.router.navigate(['/home']);
          window.location.reload();
        },
        error => {
          alert('Usuario o clave no inválidos');
        });
  }
  test() {
    //printJS('docs/printjs.pdf')
    //printJS('/assets/test.pdf');
    console.log('pdf test', printJS);
  }
}
