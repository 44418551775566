import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_servicios/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-olvide-clave',
  templateUrl: './olvide-clave.component.html',
  styleUrls: ['./olvide-clave.component.css']
})
export class OlvideClaveComponent implements OnInit {
  formulario: FormGroup;
  submitted = false;
  returnUrl: string;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
    if (this.authService.autenticacionValue) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit() {
    this.formulario = this.formBuilder.group(
      {
        correo: ['', Validators.required]
      }
    )

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f() { return this.formulario.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.formulario.invalid) {
      alert('Formulario Inválido');
      return;
    }
    const { correo } = this.formulario.value;
    this.authService.olvidoClave(correo)
      .subscribe(
        (data: any) => {
          if(data.status === 1){
            alert('Se envio un correo para poder restablecer la contraseña');
            this.router.navigate(['/']);
          } else {
            alert('El corrreo no pertenece a ningún usuario');
          }
        },
        () => {
          alert('El corrreo no pertenece a ningún usuario');
        });
  }
}
