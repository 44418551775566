export class Empleado {
  idEmpleado: number;
  nombres: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  fechaNacimiento: string;
  foto: string;
  direccion: string;
  telefono: string;
  celular: string;
  numeroDocumento: number;
  tipoDocumento: number;
  idLocalEmpresa: number;
  habilitado: number;
  idCargo: string;
  usuario: string;

  constructor(
    idEmpleado?: number,
    nombres?: string,
    apellidoPaterno?: string,
    apellidoMaterno?: string,
    fechaNacimiento?: string,
    foto?: string,
    direccion?: string,
    telefono?: string,
    celular?: string,
    numeroDocumento?: number,
    tipoDocumento?: number,
    idLocalEmpresa?: number,
    habilitado?: number,
    idCargo?: string,
    usuario?: string
  ) {
    this.idEmpleado = idEmpleado;
    this.nombres = nombres;
    this.apellidoPaterno = apellidoPaterno;
    this.apellidoMaterno = apellidoMaterno;
    this.fechaNacimiento = fechaNacimiento;
    this.foto = foto;
    this.direccion = direccion;
    this.telefono = telefono;
    this.celular = celular;
    this.numeroDocumento = numeroDocumento;
    this.tipoDocumento = tipoDocumento;
    this.idLocalEmpresa = idLocalEmpresa;
    this.habilitado = habilitado;
    this.idCargo = idCargo;
    this.usuario = usuario;
  }
}
